import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@peca/commons';
import { BuyerQuery } from '../../models/buyer-query.model';

@Component({
  selector: 'peca-buyer-list-search',
  templateUrl: './buyer-list-search.component.html',
  styleUrls: ['./buyer-list-search.component.scss'],
})
export class BuyerListSearchComponent {
  @Output()
  filter: EventEmitter<BuyerQuery>;

  searchForm: FormGroup = new FormGroup({
    search: new FormControl(''),
    registrationDate: new FormControl('', [Validators.date]),
    active: new FormControl(''),
    status: new FormControl(''),
    sort: new FormControl('audit.createdAt,desc'),
  });

  constructor() {
    this.filter = new EventEmitter<BuyerQuery>();
  }

  onClickSearch() {
    if (this.searchForm.invalid) return;
    this.filter.next(this.searchForm.value);
  }
}
