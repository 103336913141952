import { Component, Input } from '@angular/core';

@Component({
  selector: 'pds-icon',
  template: `
    <span
      class="material-symbols-outlined"
      [ngClass]="{
        '--small': size === 'small' && !customSize,
        '--regular': size === 'regular' && !customSize,
        '--large': size === 'large' && !customSize,
        '--larger': size === 'larger' && !customSize
      }"
      [style.color]="color"
      [style.fontWeight]="weight"
      [style.fontSize]="customSize ? customSize + 'px' : ''"
    >
      <ng-content></ng-content>
    </span>
  `,
  styleUrls: ['./pds-icon.component.scss'],
})
export class IconComponent {
  @Input() size?: 'small' | 'regular' | 'large' | 'larger';
  @Input() customSize?: string;
  @Input() color?: string;
  @Input() weight?: string;
}
