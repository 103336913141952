import { Pipe, PipeTransform } from '@angular/core';
import { OrderReturnLog } from '../models/orders-return-logs.model';

@Pipe({ name: 'log' })
export class OrderReteurnLogPipe implements PipeTransform {
  transform(value: OrderReturnLog, direction: 'from' | 'to') {
    let change = undefined;

    switch (value.changeType) {
      case 'UPLOAD_FILES':
        change = value.files;
        break;
      case 'DELETE_FILE':
        change = value.files;
        break;
      case 'UPDATE_OBSERVATION':
        change = value.observation;
        break;
      case 'UPDATE_INVOICE':
        change = value.invoice;
        break;
      case 'UPDATE_PAYMENT_STATUS':
        change = value.status;
        break;
    }

    if (!change || !change[direction]) return '-';

    return change[direction];
  }
}
