<ng-container *ngIf="tabs.length">
  <div class="pds-tabs">
    <ul>
      <ng-container *ngFor="let tab of tabs">
        <li>
          <ng-container *ngIf="tab.path; else buttonTemplate">
            <a [routerLink]="tab.path" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="--active" [title]="tab.label">
              {{ tab.label }}
            </a>
          </ng-container>

          <ng-template #buttonTemplate>
            <button role="link" [class.--active]="tab.active" [title]="tab.label" (click)="handleSelectTabEvent(tab)">
              {{ tab.label }}
            </button>
          </ng-template>
        </li>
      </ng-container>
    </ul>
  </div>

  <div class="pds-tabs__content">
    <ng-content />
  </div>
</ng-container>
