import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Validators } from '@peca/commons';
import { BuyerDocument } from '../../models/buyer-document.model';

export class BuyerDocumentForm {
  group: FormGroup;

  constructor() {
    this.group = new FormGroup({
      newFile: this.getFileFormGroup(),
      files: new FormArray([]),
    });
  }

  get controls() {
    return {
      newFile: this.group.get('newFile') as FormGroup,
      files: this.group.get('files') as FormArray,
    };
  }

  setValue(files: Array<BuyerDocument>) {
    this.controls.files.clear();
    files.forEach((file) => this.controls.files.push(this.getFileFormGroup(file)));
  }

  get value() {
    return this.controls.files.value as Array<BuyerDocument>;
  }

  removeFile(id: string) {
    const index = this.controls.files.value.findIndex((file: BuyerDocument) => file.id === id);
    this.controls.files.removeAt(index);
  }

  addFile(file: BuyerDocument) {
    const group = this.getFileFormGroup(file);
    this.controls.files.push(group);
  }

  private getFileFormGroup(file?: BuyerDocument) {
    return new FormGroup({
      id: new FormControl(file?.id || crypto.randomUUID()),
      name: new FormControl(file?.name || ''),
      status: new FormControl(file?.status || 'PENDING'),
      type: new FormControl(file?.type || '', [Validators.required]),
    });
  }
}
