import { Router } from '@angular/router';
import { Confirmable, ToastService } from '@peca/commons';
import { Pagination, BreadcrumbItem, PaginationInitialState, InsightsService } from '@peca/design-system';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { BehaviorSubject, Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { AnalyticsService } from '@peca/commons';

import { BuyersService } from '../../services/buyers.service';
import { Pageable } from '../../../../../app/core/models/pageable.model';
import { Buyer } from '../../models/buyer.model';
import { BuyerQuery } from '../../models/buyer-query.model';

@Component({
  selector: 'peca-buyer-list',
  templateUrl: './buyer-list.component.html',
  styleUrls: ['./buyer-list.component.scss'],
})
export class BuyerListComponent implements OnInit, OnDestroy {
  analytics: AnalyticsService;
  destroyRef$: Subject<void>;
  router: Router;
  service: BuyersService;
  toast: ToastService;
  buyers$: BehaviorSubject<any>;
  pagination$: Subject<Pagination>;
  changeStatusConfirmable$: Subject<Confirmable>;
  breadcrumbItems: BreadcrumbItem[] = [{ label: 'Compradores', path: '/compradores' }];
  lastQuery: BuyerQuery;
  insightsServices: InsightsService;

  constructor() {
    this.analytics = inject(AnalyticsService);
    this.router = inject(Router);
    this.service = inject(BuyersService);
    this.toast = inject(ToastService);
    this.insightsServices = inject(InsightsService);
    this.destroyRef$ = new Subject<void>();
    this.buyers$ = new BehaviorSubject<any>({ loading: true });
    this.pagination$ = new Subject<Pagination>();
    this.changeStatusConfirmable$ = new Subject<Confirmable>();
    this.lastQuery = PaginationInitialState;
  }

  ngOnInit(): void {
    this.analytics.view('buyers_list');
    this.search({ ...this.lastQuery, sort: 'audit.createdAt,desc' });
    this.service.insights().subscribe((response) => {
      this.insightsServices.push([{ type: 'text', label: 'Cadastros pendentes', value: response.pendingAnalysis }]);
    });
  }

  ngOnDestroy(): void {
    this.insightsServices.clear();
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  handleStatusChange(buyer: Buyer) {
    this.changeStatusConfirmable$.next({ confirmed: false, payload: buyer });
  }

  handleConfirmStatusChange(confirmable: Confirmable) {
    const buyer = confirmable.payload as Buyer;

    if (!confirmable.confirmed) {
      buyer.active = !buyer.active;
      return;
    }

    this.service
      .toogleStatus(buyer.id, buyer.active)
      .pipe(takeUntil(this.destroyRef$))
      .subscribe({
        next: () => this.toast.success('O status do comprador foi alterado com sucesso.'),
        error: (e: unknown) => {
          const message = 'Ocorreu um erro ao alterar o status do comprador.';
          console.error(JSON.stringify(e));
          this.toast.failure(message);
          buyer.active = !buyer.active;
        },
      });
  }

  handleFilterChangesEvent(_query: any) {
    const query = { ...PaginationInitialState, ..._query };
    this.search(query);
    this.pagination$.next(PaginationInitialState);
  }

  handlePaginateEvent(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.search(query);
  }

  search(query: BuyerQuery) {
    this.lastQuery = query;
    this.buyers$.next({ loading: true });
    this.service
      .search(query)
      .pipe(takeUntil(this.destroyRef$), distinctUntilChanged())
      .subscribe({
        next: (data) => {
          this.paginate(data);
          this.buyers$.next({ data });
          this.analytics.callback('buyers_list_search', { success: true, query });
        },
        error: () => {
          this.buyers$.next({ failure: true });
          this.analytics.callback('buyers_list_search', { success: false, query });
        },
      });
  }

  handleBuyerDetailsClickEvent(buyer: Buyer) {
    this.router.navigate(['/compradores', buyer.id]);
  }

  handleBuyerEditClickEvent(buyer: Buyer) {
    this.router.navigate(['/compradores', buyer.id, 'editar']);
  }

  paginate(page: Pageable<unknown>) {
    this.pagination$.next({
      page: page.currentPage + 1,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    });
  }
}
