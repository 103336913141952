<div class="order-search">
  <pds-form [formGroup]="form" (ngSubmit)="onSubmit()">
    <pds-form-row grid="1-1-1-1">
      <pds-form-field label="ID do Pedido">
        <input type="text" formControlName="search" placeholder="PA-002668" />
      </pds-form-field>
      <pds-form-field label="Comprador">
        <pds-autocomplete placeholder="Nome fantasia ou razão social" formControlName="buyer" [source]="buyers$" (autocomplete)="onAutocompleteBuyers($event)" />
      </pds-form-field>
      <pds-form-field label="Data do pedido">
        <peca-range-form formControlName="date" />
      </pds-form-field>
      <pds-form-field label="Status">
        <pds-select formControlName="status" [options]="statusSource" />
      </pds-form-field>
    </pds-form-row>

    <pds-form-row grid="1-1-1-1">
      <pds-form-field label="Canal de Venda">
        <pds-autocomplete formControlName="salesChannelId" [source]="salesChannels$" (autocomplete)="onAutocompleteSalesChannel($event)" />
      </pds-form-field>
    </pds-form-row>
    <pds-form-actions>
      <pds-button type="submit">Buscar</pds-button>
    </pds-form-actions>
  </pds-form>
</div>
