import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { environment } from 'apps/backoffice/src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { OrderPayment } from '../../../models/order-payments.model';
import { AnalyticsService } from '@peca/commons';

@Component({
  templateUrl: './order-details-payments.component.html',
  styleUrls: ['./order-details-payments.component.scss'],
})
export class OrderDetailsPaymentsComponent implements OnInit, OnDestroy {
  analytics: AnalyticsService;

  http = inject(HttpClient);
  destroyRef$ = new Subject<void>();
  route = inject(ActivatedRoute);
  payments$ = new BehaviorSubject<AsyncState<OrderPayment[]>>({
    loading: true,
  });

  constructor() {
    this.analytics = inject(AnalyticsService);
  }

  get orderId() {
    return this.route.parent?.snapshot.params['orderId'] as string;
  }

  ngOnInit() {
    const orderId = this.orderId;
    this.fetchOrderPayments(orderId);
    this.analytics.view('order_details_payments');
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  fetchOrderPayments(orderId: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/orders/${orderId}/payments`;
    return this.http.get<OrderPayment[]>(resource).subscribe({
      next: this.onFetchOrderPaymentsSuccess.bind(this),
      error: this.onFetchOrderPaymentsFailure.bind(this),
    });
  }

  onFetchOrderPaymentsSuccess(payments: OrderPayment[]) {
    this.payments$.next({ data: payments });
  }

  onFetchOrderPaymentsFailure(e: unknown) {
    console.error(e);
    this.payments$.next({ failure: true });
  }
}
