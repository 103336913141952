import { Component, Input } from '@angular/core';

@Component({
  selector: 'pds-link[path]',
  template: `
    <a [routerLink]="path" class="pds-link --{{ modifier }}">
      <ng-content></ng-content>
    </a>
  `,
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent {
  @Input()
  modifier: 'primary' | 'outlined';

  @Input()
  path!: string;

  constructor() {
    this.modifier = 'primary';
  }
}
