<pds-module-header title="Transportadoras" ctaPath="/transportadoras/{{ carrierId }}/tipo-de-entrega/{{ deliveryTypeId }}" ctaLabel="Voltar" modifier="outlined">
  <pds-new-breadcrumb [items]="breadcrumbItems"></pds-new-breadcrumb>

  <pds-panel>
    <pds-form [formGroup]="form">
      <pds-form-row grid="1-1-1-1-1">
        <pds-form-field label="Descrição">
          <input type="text" formControlName="description" />
        </pds-form-field>

        <pds-form-field label="Início origem">
          <input type="text" formControlName="originZipCodeStart" pdsMask="99999-999" />
        </pds-form-field>
        <pds-form-field label="Fim origem">
          <input type="text" formControlName="originZipCodeEnd" pdsMask="99999-999" />
        </pds-form-field>
        <pds-form-field label="Início destino">
          <input type="text" formControlName="destinationZipCodeStart" pdsMask="99999-999" />
        </pds-form-field>
        <pds-form-field label="Fim destino">
          <input type="text" formControlName="destinationZipCodeEnd" pdsMask="99999-999" />
        </pds-form-field>
      </pds-form-row>
      <pds-form-actions>
        <pds-button (click)="handleSubmitForm()" [disabled]="form.invalid || form.disabled">adicionar</pds-button>
      </pds-form-actions>
    </pds-form>
  </pds-panel>

  <hr />

  <ng-container *ngIf="regions$ | async; let regions">
    <ng-container *ngIf="regions.loading">
      <span>carregando...</span>
    </ng-container>

    <ng-container *ngIf="regions.failure">
      <strong>Falha ao obter regiões de atuação</strong>
    </ng-container>

    <ng-container *ngIf="regions.data">
      <ng-container *ngIf="!regions.data?.length">
        <strong>Nenhuma região de atuação cadastrada.</strong>
      </ng-container>

      <pds-table *ngIf="regions.data?.length">
        <table>
          <thead>
            <tr>
              <th rowspan="2">Descrição</th>
              <th colspan="2">Origem</th>
              <th colspan="2">Destino</th>
              <th rowspan="2">Status</th>
              <th rowspan="2"></th>
            </tr>
            <tr>
              <th>CEP Inicial</th>
              <th>CEP Final</th>
              <th>CEP Inicial</th>
              <th>CEP Final</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let region of regions.data">
              <tr>
                <td>{{ region.description }}</td>
                <td>{{ region.originZipCodeStart }}</td>
                <td>{{ region.originZipCodeEnd }}</td>
                <td>{{ region.destinationZipCodeStart }}</td>
                <td>{{ region.destinationZipCodeEnd }}</td>
                <td>
                  <pds-switch (ngModelChange)="handleStatusChanges(region, $event)" [(ngModel)]="region.active"></pds-switch>
                </td>
                <td>
                  <div class="flex">
                    <pds-icon-button icon="delete" (click)="handleRemoveEvent(region)" />
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </pds-table>
    </ng-container>

    <hr />

    <pds-form-actions>
      <pds-button icon="upload_file" (click)="fileUpload.click()">importar</pds-button>
      <input #fileUpload type="file" accept=".csv" (change)="handleSelectFileEvent($event)" />
    </pds-form-actions>

    <peca-confirmable-action [confirmable]="changeStatusConfirmable$" (confirm)="handleConfirmStatusChange($event)">
      <p class="mb-8">Você está alterando o status de uma área de atuação.</p>
      <p>Deseja continuar?</p>
    </peca-confirmable-action>

    <peca-confirmable-action [confirmable]="removeConfirmable$" (confirm)="handleRemoveChange($event)">
      <p class="mb-8">Você está removendo uma área de atuação.</p>
      <p>Deseja continuar?</p>
    </peca-confirmable-action>

    <peca-confirmable-action [confirmable]="uploadConfirmable$" (confirm)="handleUploadChange($event)">
      <p class="mb-8">Importar um arquivos de areas de regiões apagará todas as regiões cadastradas para esse tipo de entrega.</p>
      <p>Deseja continuar?</p>
    </peca-confirmable-action>
  </ng-container>
</pds-module-header>
