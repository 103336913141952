<div class="carrier-search">
  <pds-form [formGroup]="form">
    <pds-form-row grid="1">
      <pds-form-field label="Busca">
        <input type="text" formControlName="search" placeholder="Pesquise por CNPJ, nome fantasia, CEP ou e-mail." />
      </pds-form-field>
    </pds-form-row>

    <div class="carrier-search__actions">
      <pds-button type="submit" (click)="handleSearchClickEvent()">Buscar</pds-button>
    </div>
  </pds-form>
</div>
