/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AsyncState } from './async-state.model';

@Component({
  selector: 'pds-async-state',
  templateUrl: './async-state.component.html',
  styleUrls: ['./async-state.component.scss'],
})
export class AsyncStateComponent {
  @Input('state')
  state$: BehaviorSubject<AsyncState<any>>;

  @Input()
  title: string;

  constructor() {
    this.state$ = new BehaviorSubject<AsyncState<any>>({ loading: true });
    this.title = 'dados';
  }
}
