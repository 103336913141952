<div class="pds-async-state">
  <ng-container *ngIf="state$ | async; let state">
    <ng-container *ngIf="state.loading">
      <strong>carregando...</strong>
    </ng-container>

    <ng-container *ngIf="state.failure">
      <strong>Falha ao carregar {{ title }}.</strong>
    </ng-container>

    <ng-container *ngIf="!state.loading && !state.failure">
      <ng-content />
    </ng-container>
  </ng-container>
</div>
