import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { UserExpressService } from './user-express.service';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ToastService } from '../toast/toast.service';
import { Validators } from '../../validators';
import { BehaviorSubject, of } from 'rxjs';

import { UserExpress } from './user-express.model';
import { MultipleItem } from '@peca/design-system';

@Component({
  selector: 'peca-user-express',
  templateUrl: './user-express.component.html',
  styleUrls: ['./user-express.component.scss'],
})
export class UserExpressComponent implements AfterViewInit {
  service: UserExpressService;
  form: FormGroup;
  toast: ToastService;
  groups$: BehaviorSubject<MultipleItem[]>;

  @Output()
  created: EventEmitter<UserExpress>;

  constructor() {
    this.groups$ = new BehaviorSubject<MultipleItem[]>([]);
    this.toast = inject(ToastService);
    this.service = inject(UserExpressService);
    this.form = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      cpf: new FormControl('', [Validators.required, Validators.cpf]),
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
        asyncValidators: [
          (control: AbstractControl) => {
            if (!control.value || control.pristine) return of(null);
            return this.service.validateEmailAvailability(control.value);
          },
        ],
        updateOn: 'blur',
      }),
      roles: new FormControl([]),
      groups: new FormControl([]),
    });

    this.created = new EventEmitter<UserExpress>();
  }

  ngAfterViewInit() {
    this.service.fetchGroups().subscribe((groups) => this.groups$.next(groups));
  }

  onClickSubmit() {
    if (this.form.invalid) return;
    this.service.create(this.form.value).subscribe({
      next: (user: UserExpress) => {
        this.toast.success('Usuário cadastrado com sucesso');
        this.created.next(user);
        this.form.patchValue({
          firstName: '',
          lastName: '',
          cpf: '',
          email: '',
          groups: [],
          roles: [],
        });
      },
      error: () => {
        this.toast.failure('Falha ao cadastrar usuário');
      },
    });
  }
}
