<div class="order-details-products" *ngIf="products$ | async; let products">
  <ng-container *ngIf="products.loading">
    <strong>carregando...</strong>
  </ng-container>

  <ng-container *ngIf="products.failure">
    <strong>Não foi possivel listar os produtos do pedido.</strong>
  </ng-container>

  <ng-container *ngIf="products.data && !products.data.length">
    <strong>Este pedido não possuí produtos.</strong>
  </ng-container>

  <pds-table *ngIf="products.data && products.data.length">
    <table>
      <thead>
        <tr>
          <th rowspan="2">Vendedor</th>
          <th colspan="2">Quantidade</th>
          <th rowspan="2">Nome</th>
          <th rowspan="2">PN</th>
          <th rowspan="2">Marca</th>
          <th rowspan="2">Código Seller</th>
          <th rowspan="2">Valor unitário</th>
          <th rowspan="2">Desconto unitário</th>
          <th rowspan="2">Valor total</th>
          <th rowspan="2"></th>
        </tr>
        <tr>
          <th>Adquirida</th>
          <th>Devolvida</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let product of products.data; let i = index">
          <td>
            <div class="flex flex-column gap-4">
              <strong>{{ product.branch.tradingName }}</strong>
              <small>{{ product.branch.commercialName }} ({{ product.branch.externalReference }})</small>
            </div>
          </td>
          <td>{{ product.quantity }}</td>
          <td>{{ product.returnedQuantity || '-' }}</td>
          <td>{{ product.description }}</td>
          <td>{{ product.partNumber }}</td>
          <td>{{ product.brand }}</td>
          <td>{{ product.branch.code }}</td>
          <td>{{ product.unitPrice | currency : 'BRL' }}</td>
          <td>{{ product.unitDiscountPrice | currency : 'BRL' }}</td>
          <td>{{ product.totalPrice | currency : 'BRL' }}</td>
          <td pdsColActions>
            <pds-icon-button title="Substituir vendedor" icon="store" (click)="onClickReplaceSeller(product)" />
          </td>
        </tr>
      </tbody>
    </table>
  </pds-table>

  <peca-order-replace-seller #replaceSellerComponent (adjust)="onAdjust()" />
</div>
