import { Injectable } from '@angular/core';
import { prepareQuery } from '@peca/commons';
import { catchError, combineLatest, map, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Buyer } from '../models/buyer.model';
import { BuyerQuery } from '../models/buyer-query.model';
import { Pageable } from '../../../core/models/pageable.model';
import { BuyerDocument } from '../models/buyer-document.model';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class BuyersService {
  private readonly resource: string;

  constructor(private http: HttpClient) {
    this.resource = `${environment.gateway.url}/backoffice/v1/buyers`;
  }

  create(payload: unknown) {
    return this.http.post(this.resource, payload);
  }

  update(id: string, payload: unknown) {
    return this.http.patch(`${this.resource}/${id}`, payload);
  }

  search(query: BuyerQuery) {
    const params = prepareQuery(query);

    return this.http.get<Pageable<Buyer>>(this.resource, { params });
  }

  fetchDetails(id: string) {
    const details = this.http.get<Buyer>(`${this.resource}/${id}`);
    const files = this.http.get<Array<BuyerDocument>>(`${this.resource}/${id}/files`);

    return combineLatest({ details, files });
  }

  updateShippingAddress(buyer: string, address: string, payload: unknown) {
    if (!address) return this.http.post(`${this.resource}/${buyer}/addresses`, payload);
    else return this.http.put(`${this.resource}/${buyer}/addresses/${address}`, payload);
  }

  toogleStatus(id: string, active: boolean) {
    return this.http.patch(`${this.resource}/${id}/active`, { active });
  }

  deleteDocument(buyer: string, file: string) {
    return this.http.delete(`${this.resource}/${buyer}/files/${file}`);
  }

  uploadDocument(id: string, files: FormData) {
    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });

    return this.http.post<Array<BuyerDocument>>(`${this.resource}/${id}/files`, files, { headers });
  }

  downloadDocument(buyer: string, file: string) {
    const resource = `${this.resource}/${buyer}/files/${file}`;
    const responseType = 'arraybuffer';
    const observe = 'response';

    return this.http.get(resource, { responseType, observe }).pipe(map(this.mapFileDownload));
  }

  suggestFarmers(search: string) {
    const resource = `${environment.gateway.url}/accounts/v1/users/suggestions?search=${search}`;

    return this.http.get<{ firstName: string; lastName: string; id: string; email: string }[]>(resource).pipe(
      map((items) =>
        items.map((item) => ({
          id: item.id,
          email: item.email,
          name: `${item.firstName} ${item.lastName}`,
        }))
      ),
      catchError(() => of(null))
    );
  }

  fetchProductTypes() {
    const resource = `${environment.gateway.url}/backoffice/v1/product-types`;
    return this.http.get<Array<string>>(resource);
  }

  private mapFileDownload(res: HttpResponse<ArrayBuffer>) {
    const { status, body } = res;
    const contentType = res.headers.get('Content-Type');

    if (status !== 200 || !body || !contentType) {
      return throwError(() => new Error(res.statusText));
    }

    return of(URL.createObjectURL(new Blob([body], { type: contentType })));
  }

  insights() {
    return this.http.get<any>(`${this.resource}/insights`);
  }

  reject(buyerId: string, reason: string) {
    const status = 'REJECTED';
    return this.http.patch(`${this.resource}/${buyerId}/administrative-statuses`, { reason, status });
  }

  approve(buyerId: string) {
    const status = 'APPROVED';
    return this.http.patch(`${this.resource}/${buyerId}/administrative-statuses`, { status });
  }
}
