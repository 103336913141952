<pds-module-header title="Compradores" ctaLabel="Novo Comprador" ctaPath="/compradores/novo">
  <pds-new-breadcrumb [items]="breadcrumbItems"></pds-new-breadcrumb>

  <pds-panel>
    <peca-buyer-list-search (filter)="handleFilterChangesEvent($event)" />

    <hr />

    <ng-container *ngIf="buyers$ | async; let buyers">
      <ng-container *ngIf="buyers.data?.items?.length">
        <pds-table>
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>CNPJ</th>
                <th>Responsável</th>
                <th>E-mail</th>
                <th>Cadastro</th>
                <th>Situação</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let buyer of buyers.data?.items">
                <tr data-testid="buyer-list-item">
                  <td>
                    <div class="buyer-list-item__company-name">
                      <strong>{{ buyer.companyName }}</strong>
                      <small>{{ buyer.tradingName }}</small>
                    </div>
                  </td>
                  <td>{{ buyer.cnpj }}</td>
                  <td>{{ buyer.responsible.name }}</td>
                  <td>{{ buyer.responsible.contacts | contacts : 'EMAIL' }}</td>
                  <td>{{ buyer.audit.createdAt | date : 'dd/MM/yyyy' }}</td>
                  <td>
                    <pds-chip [content]="buyer.administrativeStatus | adminStatus" />
                  </td>
                  <td pdsColActions>
                    <pds-switch (ngModelChange)="handleStatusChange(buyer)" [(ngModel)]="buyer.active"></pds-switch>
                  </td>
                  <td pdsColActions>
                    <pds-icon-button icon="visibility" (click)="handleBuyerDetailsClickEvent(buyer)" />
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </pds-table>
      </ng-container>

      <ng-container *ngIf="buyers.loading">
        <strong>carregando...</strong>
      </ng-container>

      <ng-container *ngIf="buyers.failure">
        <strong>Não foi possível listar os compradores. Por favor, tente novamente mais tarde.</strong>
      </ng-container>

      <ng-container *ngIf="!!buyers.data && !buyers.data?.items?.length">
        <strong data-testid="no-buyer-list-item">Nenhum comprador encontrado.</strong>
      </ng-container>

      <pds-pagination [pagination]="pagination$" (paginate)="handlePaginateEvent($event)"></pds-pagination>
    </ng-container>
  </pds-panel>
</pds-module-header>

<peca-confirmable-action [confirmable]="changeStatusConfirmable$" (confirm)="handleConfirmStatusChange($event)">
  <p class="mb-8">Você está alterando o status de um comprador.</p>
  <p>Deseja continuar?</p>
</peca-confirmable-action>
