import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { Log } from '@peca/backoffice/models/logs/log.model';

import { OrderService } from '../../../services/order.service';
import { AsyncState } from '../../../../../core/models/async-state.model';
import { AnalyticsService } from '@peca/commons';

@Component({
  selector: 'peca-order-details-logs',
  templateUrl: './order-details-logs.component.html',
})
export class OrderDetailsLogsComponent implements OnInit, OnDestroy {
  orders: OrderService;
  route: ActivatedRoute;
  destroyRef$: Subject<void>;
  logs$: BehaviorSubject<AsyncState<Log[]>>;
  analytics: AnalyticsService;

  constructor() {
    this.orders = inject(OrderService);
    this.route = inject(ActivatedRoute);
    this.analytics = inject(AnalyticsService);
    this.destroyRef$ = new Subject();
    this.logs$ = new BehaviorSubject<AsyncState<Log[]>>({ loading: true });
  }

  get orderId() {
    if (!this.route.snapshot.parent) return '';

    const { orderId } = this.route.snapshot.parent.params;
    return orderId as string;
  }

  ngOnInit() {
    const orderId = this.orderId;
    this.fetch(orderId);
    this.analytics.view('order_details_logs');
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  fetch(orderId: string) {
    this.logs$.next({ loading: true });
    this.orders
      .fetchOrderLogs(orderId)
      .pipe(takeUntil(this.destroyRef$))
      .subscribe({
        next: this.onFetchSuccess.bind(this),
        error: this.onFetchFailure.bind(this),
      });
  }

  onFetchSuccess(logs: Log[]) {
    this.logs$.next({ data: logs });
  }

  onFetchFailure(e: unknown) {
    console.error(e);
    this.logs$.next({ failure: true });
  }
}
