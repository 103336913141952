<div class="peca-confirmable-action" [class.--active]="action">
  <div class="peca-confirmable-action__modal">
    <h2 class="peca-confirmable-action__modal__title">Confirme sua ação</h2>
    <div class="peca-confirmable-action__modal__content">
      <p class="mb-8">{{ action?.message }}</p>
      <p class="mb-8">
        <span *ngIf="action?.unrevertable">Esta ação não poderá ser desfeita. </span>
        <span>Deseja continuar?</span>
      </p>
    </div>

    <div class="peca-confirmable-action__modal__actions">
      <pds-button modifier="outlined" (click)="onClickConfirm(false)">Cancelar</pds-button>
      <pds-button (click)="onClickConfirm(true)">Confirmar</pds-button>
    </div>
  </div>
</div>
