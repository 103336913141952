<div class="order-details-purchases" *ngIf="purchases$ | async; let purchases">
  <ng-container *ngIf="purchases.data && purchases.data.length">
    <pds-table>
      <table>
        <thead>
          <tr>
            <th>Vendedor</th>
            <th>Status</th>
            <th>Valor</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let order of purchases.data">
            <td>
              <div class="flex flex-column gap-4">
                <strong>{{ order.branch.tradingName }}</strong>
                <small>{{ order.branch.commercialName }} ({{ order.branch.externalReference }})</small>
              </div>
            </td>
            <td>
              <pds-chip [content]="order.status | logTypeStatus" />
            </td>
            <td>{{ order.amount | currency : 'BRL' }}</td>
            <td pdsColActions style="width: 10%">
              <pds-icon-button title="Ver detalhes" icon="visibility" (click)="onClickDetails(order)" />
              <pds-icon-button title="Copiar ordem" icon="copy_all" [pecaPurchaseToClipboard]="order" (copy)="onCopy($event)" />
            </td>
          </tr>
        </tbody>
      </table>
    </pds-table>
  </ng-container>

  <ng-container *ngIf="purchases.loading">
    <strong>carregando...</strong>
  </ng-container>

  <ng-container *ngIf="purchases.failure">
    <strong>Não foi possível carregar os pedidos de compra desta venda.</strong>
  </ng-container>

  <ng-container *ngIf="purchases.data && !purchases.data.length">
    <strong>Esta venda não possuí pedidos de compra.</strong>
  </ng-container>
</div>
