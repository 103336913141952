import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { KeycloakCredentialsService } from '../../services/keycloak-credentials.service';

@Directive({
  selector: '[allowTo]',
  standalone: true,
})
export class AllowToDirective {
  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private credentials: KeycloakCredentialsService
  ) {}

  @Input()
  set allowTo(roles: string[]) {
    const hasRole = this.hasRole(roles);

    if (!hasRole) {
      this.viewContainer.clear();
      return;
    }

    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private hasRole(roles: string[] = []) {
    if (!roles.length) return true;
    return this.credentials.roles?.some((r) => roles.includes(r));
  }
}
