import { Directive, ElementRef, inject, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: 'input[pdsFormControl], select[pdsFormControl], textarea[pdsFormControl]',
})
export class PDSFormControlDirective implements OnInit {
  elementRef: ElementRef;
  renderer: Renderer2;

  constructor() {
    this.elementRef = inject(ElementRef);
    this.renderer = inject(Renderer2);
  }

  get hostElement() {
    return this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.renderer.addClass(this.hostElement, 'pds-form-control');
  }
}
