import { AbstractControl } from '@angular/forms';

function parseNumber(value: string) {
  return Number(
    value
      .toString()
      .replace(/,/g, '.')
      .replace(/[^\d.-]/g, '')
  );
}

export const number = {
  min: (min: number, parse = false) => {
    return (control: AbstractControl) => {
      if (control.value === '' || control.value === null || control.value === undefined) return null;

      const value = !parse ? Number(control.value) : parseNumber(control.value);

      if (!isNaN(value) && value >= min) return null;

      return {
        min: { message: `O valor deve ser maior ou igual a ${min}` },
      };
    };
  },
  max: (max: number, parse = false) => {
    return (control: AbstractControl) => {
      if (!control.value) return null;

      const value = !parse ? Number(control.value) : parseNumber(control.value);

      if (!isNaN(value) && value <= max) return null;

      return {
        max: { message: `O valor deve ser menor ou igual a ${max}` },
      };
    };
  },
};
