import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatusType } from '../models/order-status';

@Pipe({
  name: 'orderStatus',
})
export class OrderStatusPipe implements PipeTransform {
  status: Record<OrderStatusType, { label: string; color: string }> = {
    PROCESSING_PAYMENT: { label: 'Processando Pagamento', color: '#A8DADC' },
    PAYMENT_ACCEPT: { label: 'Pagamento Aprovado', color: '#A8E6CF' },
    PAYMENT_REFUSED: { label: 'Pagamento Recusado', color: '#F8BBD0' },
    REQUIRES_MANUAL_ADJUSTMENT: { label: 'Aguardando Ajustes', color: '#FFD180' },
    WAITING_FOR_SELLER: { label: 'Aguardando Vendedor', color: '#E6E6FA' },
    IN_SEPARATION: { label: 'Em Separação', color: '#B2EBF2' },
    AWAITING_COLLECTION: { label: 'Aguardando Coleta', color: '#CCFF90' },
    ON_ROUTE: { label: 'Coletado Pela Transportadora', color: '#B3E5FC' },
    DELIVERED: { label: 'Entregue', color: '#81ecec' },
    CANCELED: { label: 'Cancelado', color: '#F8BBD0' },
    PROCESSING_RETURN: { label: 'Processando Devolução', color: '#FFE0B2' },
    PARTIAL_RETURN: { label: 'Devolvido Parcialmente', color: '#FFAB91' },
    TOTAL_RETURN: { label: 'Devolvido', color: '#FFCDD2' },
    MISPLACED: { label: 'Extraviado', color: '#FFF9C4' },
  };

  transform(key: OrderStatusType, kind: 'label' | 'color' = 'label') {
    const status = this.status[key];

    if (!status) {
      return kind === 'label' ? key : '#F4F4F4';
    }

    return status[kind];
  }
}
