<div class="buyer-list-search">
  <pds-form [formGroup]="searchForm">
    <pds-form-row grid="1-1-1-1">
      <pds-form-field label="Busca">
        <input type="text" placeholder="Pesquise por CNPJ, nome comercial ou e-mail" formControlName="search" />
      </pds-form-field>

      <pds-form-field label="Data de cadastro">
        <pds-date-input formControlName="registrationDate"></pds-date-input>
      </pds-form-field>

      <pds-form-field label="Status">
        <select formControlName="active">
          <option value="">Todos</option>
          <option value="true">Ativos</option>
          <option value="false">Inativos</option>
        </select>
      </pds-form-field>

      <pds-form-field label="Situação Cadastral">
        <select formControlName="status">
          <option value="">Todos</option>
          <option value="PENDING">Pendente de análise</option>
          <option value="REJECTED">Reprovados</option>
          <option value="APPROVED">Aprovados</option>
        </select>
      </pds-form-field>
    </pds-form-row>

    <pds-form-actions>
      <pds-button type="submit" (click)="onClickSearch()">Buscar</pds-button>
    </pds-form-actions>
  </pds-form>
</div>
