import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbItem, Pagination, PaginationInitialState } from '@peca/design-system';
import { OrdersReturnQuery } from '../../models/orders-return-search.model';
import { Async } from '@peca/backoffice/utils/async.util';
import { BehaviorSubject, Subject } from 'rxjs';
import { AnalyticsService } from '@peca/commons';
import { Pageable } from '@peca/backoffice/models/pageable.model';
import { Router } from '@angular/router';

import { OrdersReturnService } from '../../services/orders-return.service';
import { OrderReturn } from '../../models/orders-return.model';
import { PersistQueryService } from '@peca/backoffice/services/persist-query.service';

@Component({
  selector: 'peca-orders-return-list',
  templateUrl: './orders-return-list.component.html',
  styleUrls: ['./orders-return-list.component.scss'],
})
export class OrdersReturnListComponent implements OnInit, OnDestroy {
  breadcrumb: BreadcrumbItem[];
  returns: Async<OrderReturn[]>;
  pagination$: BehaviorSubject<Pagination>;
  destroyRef$: Subject<void>;
  lastQuery: OrdersReturnQuery;
  analytics: AnalyticsService;
  ordersReturnService: OrdersReturnService;
  router: Router;
  persistQueryService: PersistQueryService;

  constructor() {
    this.breadcrumb = [{ label: 'Devoluções' }];
    this.returns = new Async<OrderReturn[]>();
    this.pagination$ = new BehaviorSubject<Pagination>({ ...PaginationInitialState, sort: 'audit.createdAt,desc' });
    this.destroyRef$ = new Subject<void>();
    this.lastQuery = { ...PaginationInitialState, sort: 'audit.createdAt,desc' };
    this.analytics = inject(AnalyticsService);
    this.ordersReturnService = inject(OrdersReturnService);
    this.router = inject(Router);
    this.persistQueryService = inject(PersistQueryService);
  }

  ngOnInit() {
    const persitedQuery = this.persistQueryService.read<OrdersReturnQuery>('peca-return-list');
    const query = persitedQuery || this.lastQuery;

    this.analytics.view('devolucoes');
    this.fetchReturns(query);
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  fetchReturns(query: OrdersReturnQuery) {
    this.lastQuery = { ...query };
    this.persistQueryService.rewriteQueryString('peca-return-list', this.lastQuery);
    this.returns.loading();
    this.ordersReturnService.fetchOrdersReturn(query).subscribe({
      next: (response) => {
        this.paginate(response);
        this.returns.loaded(response.items);
      },
      error: () => {
        this.returns.failed();
      },
    });
  }

  onSearchEvent(search: OrdersReturnQuery) {
    const query = { ...search, sort: 'audit.createdAt,desc', ...PaginationInitialState };
    this.fetchReturns(query);
  }

  onRetryEvent() {
    this.fetchReturns(this.lastQuery);
  }

  onClickDetails(order: OrderReturn) {
    this.router.navigate(['/devolucoes', order.id]);
  }

  onPaginate(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetchReturns(query);
  }

  paginate(page: Pageable<unknown>) {
    this.pagination$.next({
      page: page.currentPage + 1,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    });
  }
}
