import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { CarrierQuery } from '../../models/carrier-query.model';

@Component({
  selector: 'peca-carrier-search',
  templateUrl: './carrier-search.component.html',
  styleUrls: ['./carrier-search.component.scss'],
})
export class CarrierSearchComponent {
  @Output()
  search: EventEmitter<CarrierQuery>;

  form: FormGroup;

  constructor() {
    this.search = new EventEmitter<CarrierQuery>();
    this.form = new FormGroup({ search: new FormControl('') });
  }

  handleSearchClickEvent() {
    this.search.next(this.form.value);
  }
}
