import { Pipe, PipeTransform } from '@angular/core';

interface Module {
  name: string;
  modules: Array<{
    roles: Array<string>;
  }>;
}

@Pipe({
  name: 'moduleRoles',
})
export class ModuleRolesPipe implements PipeTransform {
  transform(module: Module) {
    return module.modules.map((m) => m.roles).flat();
  }
}
