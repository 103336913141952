import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';

import { UserExpress } from './user-express.model';
import { catchError, map, of } from 'rxjs';

@Injectable({ providedIn: 'any' })
export class UserExpressService {
  private http: HttpClient;
  private gateway: string;

  constructor(@Inject('gatewayUrl') gatewayUrl: string) {
    this.http = inject(HttpClient);
    this.gateway = `${gatewayUrl}`;
  }

  create(user: UserExpress) {
    const resource = `${this.gateway}/accounts/v1/users`;
    return this.http.post<UserExpress>(resource, user);
  }

  fetchGroups() {
    const resource = `${this.gateway}/accounts/v1/groups`;
    return this.http.get<Array<any>>(resource).pipe(map((groups) => groups.map(({ name, id }) => ({ label: name, id }))));
  }

  validateEmailAvailability(value: string) {
    const resource = `${this.gateway}/accounts/v1/users/email/available`;
    const params = { value };

    return this.http.get(resource, { params }).pipe(
      map(() => null),
      catchError((e) => {
        const message = `O e-mail "${value}" já está em uso`;

        if (e.status === 409) {
          return of({ conflicted: { message } });
        }

        console.error('Verificação de disponibilidade executada via fallback');
        return of(null);
      })
    );
  }
}
