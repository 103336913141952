import { Routes } from '@angular/router';
import { BuyerListComponent } from './components/buyer-list/buyer-list.component';
import { BuyerFormComponent } from './components/buyer-form/buyer-form.component';

export const routes: Routes = [
  {
    path: 'novo',
    component: BuyerFormComponent,
    data: { operation: 'CREATE_BUYER' },
  },
  {
    path: ':id',
    component: BuyerFormComponent,
    data: { operation: 'EDIT_BUYER' },
  },
  {
    path: '',
    component: BuyerListComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];

export const BuyersModuleLoad = () => import('./buyers.module').then(({ BuyersModule }) => BuyersModule);
