<div class="peca-confirmable-action" [class.--active]="payload">
  <div class="peca-confirmable-action__modal">
    <h2 class="peca-confirmable-action__modal__title">Confirme sua ação</h2>
    <div class="peca-confirmable-action__modal__content">
      <ng-content></ng-content>
    </div>
    <div class="peca-confirmable-action__modal__actions">
      <pds-button modifier="outlined" (click)="handleConfirmEvent(false)">Cancelar</pds-button>
      <pds-button (click)="handleConfirmEvent(true)">Confirmar</pds-button>
    </div>
  </div>
</div>
