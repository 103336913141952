import { Injectable } from '@angular/core';
import { BaseHttpClient } from '@peca/commons';
import { MultipleItem } from '@peca/design-system';
import { catchError, map, of } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Pageable } from '../../../core/models/pageable.model';
import { SalesChannel } from '../models/sales-channels.model';
import { SalesChannelsQuery } from '../models/sales-channels-request.model';
import { SallesChannelRequest } from '../models/sales-channel-request.model';
import { SallesChannelResponse } from '../models/sales-channel-response.model';

@Injectable({ providedIn: 'root' })
export class SalesChannelsService extends BaseHttpClient {
  private readonly resource = `${environment.gateway.url}/backoffice/v1/sales-channel`;

  constructor() {
    super();
  }

  fetch(query: SalesChannelsQuery) {
    const params = this.prepareQuery(query);
    return this.http.get<Pageable<SalesChannel>>(this.resource, { params });
  }

  fetchDetails(channelId: string) {
    const resource = `${this.resource}/${channelId}`;
    return this.http.get<SallesChannelResponse>(resource).pipe(
      map(
        (response) =>
          ({
            id: response.id,
            active: response.active,
            name: response.name,
            clientId: response.clientId,
            clientSecret: response.clientSecret,
            userId: response.userId,
            convenienceFee: response.convenienceFee,
            buyersIds: response.buyers.map(({ id }) => id),
            paymentMethodsIds: response.paymentMethods.map(({ id }) => id),
            applyCartStrategy: response.applyCartStrategy,
            cartStrategy: response.cartStrategy,
            showPartNumber: response.showPartNumber,
            faster: response.faster,
            deliveryTime: response.deliveryTime,
            applyDialect: response.applyDialect,
            searchByPlate: response.searchByPlate,
            vehicleSearchView: response.vehicleSearchView,
            searchLimit: response.searchLimit,
            searchByFragaId: response.searchByFragaId,
            freightType: response.freightType || '',
          } as SallesChannelRequest)
      )
    );
  }

  updateStatus(salesChannelId: number | string, active: boolean) {
    return this.http.patch(`${this.resource}/${salesChannelId}/active`, {
      active,
    });
  }

  delete(salesChannelId: number) {
    return this.http.delete(`${this.resource}/${salesChannelId}`);
  }

  save(channel: SallesChannelRequest) {
    if (!channel.id) return this.create(channel);
    else return this.update(channel);
  }

  fetchPaymentMethods() {
    const resource = `${environment.gateway.url}/backoffice/v1/payment-methods`;
    return this.http.get<{ id: string; name: string; children: { id: string; name: string }[] }[]>(resource).pipe(
      map((methods) => {
        const items = [];
        for (const method of methods) {
          items.push({ id: method.id, label: method.name });

          if (method.children) for (const child of method.children) items.push({ id: child.id, label: child.name });
        }

        return items.sort((x, y) => (x.label > y.label ? 1 : -1)) as MultipleItem[];
      })
    );
  }

  validateAvailability(value: string, field: string, label: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/sales-channel/${field}/available`;
    const params = { value };

    if (!value) return of(null);

    return this.http.get(resource, { params }).pipe(
      map(() => null),
      catchError((e) => {
        const message = `O ${label} "${value}" já está em uso por outro canal de venda`;

        if (e.status === 409) {
          return of({ conflicted: { message } });
        }

        console.error('Verificação de disponibilidade executada via fallback');
        return of(null);
      })
    );
  }

  fetchBuyers(search?: string) {
    const query = { search };
    const resource = `${environment.gateway.url}/backoffice/v1/buyers/suggestions`;
    const params = this.prepareQuery(query);
    return this.http.get<{ id: string; name: string }[]>(resource, { params }).pipe(map((sellers) => sellers.map(({ id, name }) => ({ id, label: name }))));
  }

  refreshClientSecret(channelId: string | number) {
    const resource = `${environment.gateway.url}/backoffice/v1/sales-channel/${channelId}/client-secret`;
    return this.http.patch<{ clientSecret: string }>(resource, {});
  }

  refreshRateLimit(id: string) {
    const resource = `${environment.gateway.url}/backoffice/v1/sales-channel/${id}/rate-limit`;
    return this.http.patch(resource, {});
  }

  private create(channel: SallesChannelRequest) {
    return this.http.post<{ id: string }>(this.resource, channel);
  }

  private update(channel: SallesChannelRequest) {
    const { id } = channel;
    const resource = `${this.resource}/${id}`;
    return this.http.patch<{ id: string }>(resource, channel);
  }
}
