export interface SalesChannel {
  id: number;
  name: string;
  hasConvenienceFee: boolean;
  paymentMethods: ParentPaymentMethod[];
  applyCartStrategy: boolean;
  deliveryTime: number;
  active: boolean;
  freightType: string;
}

export interface ParentPaymentMethod {
  id: number;
  referenceName: string;
  name: string;
  parent: null;
}
