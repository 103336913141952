<pds-modal #modal title="Upload de arquivo" [width]="420" (dismiss)="close()">
  <div class="upload-container" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (click)="fileInput.click()">
    <input type="file" #fileInput (change)="onFileChange($event)" hidden />
    <div class="upload-preview">
      <img *ngIf="previewImage" [src]="previewImage" alt="Preview" />
      <div *ngIf="!previewImage && !fileName" class="upload-placeholder">Arraste e solte um arquivo aqui ou clique para selecionar</div>
      <div *ngIf="fileName" class="file-info">{{ fileName }}</div>
    </div>
  </div>

  @if (file) {
  <div class="upload-actions">
    <button type="button" pdsButton (click)="onClickAttch()">anexar</button>
  </div>
  }
</pds-modal>
