import { Injectable, InjectionToken, inject } from '@angular/core';
import { KeycloakCredentialsService } from '@peca/keycloak';
import mixpanel, { Config } from 'mixpanel-browser';

export const ANALYTICS_CONFIG = new InjectionToken<AnalyticsConfig>('ANALYTICS_CONFIG');

export interface AnalyticsConfig {
  platform: string;
  environment: 'PRD' | 'HOM' | 'LOCAL';
  token: string;
  configuration: Partial<Config>;
}

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private keycloak: KeycloakCredentialsService;
  private config: AnalyticsConfig;

  constructor() {
    this.config = inject(ANALYTICS_CONFIG);
    this.keycloak = inject(KeycloakCredentialsService);
    mixpanel.init(this.config.token, this.config.configuration as Partial<Config>);
  }

  click(componentName: string, customParameters: Record<string, unknown> = {}) {
    const normalized = 'click_' + this.normalize(componentName);
    const parameters = this.normalizeParameters(normalized, customParameters);
    this.send(normalized, parameters);
  }

  view(pageName: string, customParameters: Record<string, unknown> = {}) {
    const normalized = 'view_' + this.normalize(pageName);
    const parameters = this.normalizeParameters(normalized, customParameters);
    this.send(normalized, parameters);
  }

  callback(requestName: string, customParameters: Record<string, unknown> = {}) {
    const normalized = 'callback_' + this.normalize(requestName);
    const parameters = this.normalizeParameters(normalized, customParameters);
    this.send(normalized, parameters);
  }

  error(status: number, request: Record<string, unknown>, response: Record<string, unknown>) {
    const normalized = `error_${status || 500}`;
    const parameters = this.normalizeParameters(normalized, { request, response });
    this.send(normalized, parameters);
  }

  private send(event: string, parameters: Record<string, unknown>): void {
    console.debug('AnalyticsService', { event, parameters });

    if (window.location.hostname === 'localhost') {
      return;
    }

    mixpanel.identify(this.keycloak.credentials?.email);
    mixpanel.people.set({ $name: this.keycloak.credentials?.username, $email: this.keycloak.credentials?.email });
    mixpanel.track(event, { ...parameters });
  }

  private normalize(str: string): string {
    const separatorCaracter = '_';
    return str.toLowerCase().replace(/-/g, separatorCaracter).replace(/ /g, separatorCaracter);
  }

  private normalizeParameters(event: string, parameters: Record<string, unknown>): Record<string, unknown> {
    const normalized: Record<string, unknown> = {};
    const { environment, platform } = this.config;

    Object.keys(parameters).forEach((key) => {
      const normalizedKey = `${event}_${this.normalize(key)}`;
      normalized[normalizedKey] = parameters[key];
    });

    return { ...normalized, environment, platform };
  }
}
