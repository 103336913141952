import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { Confirmable } from './confirmable-action.model';

@Component({
  selector: 'peca-confirmable-action',
  templateUrl: './confirmable-action.component.html',
  styleUrls: ['./confirmable-action.component.scss'],
})
export class ConfirmableActionComponent implements OnInit, OnDestroy {
  @Output()
  readonly confirm: EventEmitter<Confirmable>;
  readonly destroyRef$: Subject<void>;

  @Input()
  confirmable: Subject<Confirmable>;

  payload: Confirmable | null;

  constructor() {
    this.confirm = new EventEmitter<Confirmable>();
    this.confirmable = new Subject<Confirmable>();
    this.destroyRef$ = new Subject();
    this.payload = null;
  }

  ngOnInit() {
    this.confirmable.pipe(takeUntil(this.destroyRef$)).subscribe((payload) => {
      document.body.style.overflow = 'hidden';
      this.payload = payload;
    });
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  handleConfirmEvent(confirmed: boolean) {
    if (!this.payload) return;

    this.confirm.next({ ...this.payload, confirmed });
    document.body.style.overflow = 'inherit';
    this.payload = null;
  }
}
