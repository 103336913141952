import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { Provider, inject } from '@angular/core';
import { AnalyticsService } from '@peca/commons';

export class MonitoringInterceptor implements HttpInterceptor {
  analytics: AnalyticsService;

  constructor() {
    this.analytics = inject(AnalyticsService);
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    return next.handle(req.clone()).pipe(catchError((e: HttpErrorResponse) => this.handleError(req, e)));
  }

  private handleError(req: HttpRequest<unknown>, e: HttpErrorResponse) {
    const request = { url: req.url, method: req.method, payload: btoa(JSON.stringify(req.body)) };
    const response = { status: e.status, error: e.error || e.message };

    if (e instanceof HttpErrorResponse) {
      this.analytics.error(e.status, request, response);
    } else {
      this.analytics.error(500, request, response);
    }

    return throwError(() => e);
  }
}

export const monitoringInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  multi: true,
  useClass: MonitoringInterceptor,
};
