import { Component, OnInit, inject } from '@angular/core';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { BehaviorSubject } from 'rxjs';
import { OrderService } from '../../../services/order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderPurchase } from '../../../models/order-purchase.model';
import { AnalyticsService } from '@peca/commons';
import { ToastService } from '@peca/commons';

type State = AsyncState<OrderPurchase[]>;

@Component({
  templateUrl: './order-details-purchases.component.html',
})
export class OrderDetailsPurchasesComponent implements OnInit {
  purchases$: BehaviorSubject<State>;
  service: OrderService;
  route: ActivatedRoute;
  router: Router;
  analytics: AnalyticsService;
  toast: ToastService;

  constructor() {
    this.service = inject(OrderService);
    this.route = inject(ActivatedRoute);
    this.router = inject(Router);
    this.toast = inject(ToastService);
    this.analytics = inject(AnalyticsService);
    this.purchases$ = new BehaviorSubject<State>({ loading: true });
  }

  get orderId() {
    return this.route.parent?.snapshot.params['orderId'] as string;
  }

  ngOnInit() {
    this.analytics.view('order-details-purchases');
    const orderId = this.orderId;
    this.service.fetchPuchasesFromOrder(orderId).subscribe({
      next: (data) => this.purchases$.next({ data }),
      error: (e: unknown) => {
        console.error(e);
        this.purchases$.next({ failure: true });
      },
    });
  }

  onClickDetails(order: OrderPurchase) {
    this.router.navigate(['compras', order.id]);
  }

  onCopy(success: boolean) {
    if (success) this.toast.success('Pedido copiado com sucesso.');
    else this.toast.failure('Falha ao copiar o pedido.');
  }
}
