import { Injectable } from '@angular/core';
import { Subject, take } from 'rxjs';
import { Confirmation } from '../models/confirmation.model';
import { ConfirmableAction } from '../models/confirmable-action.model';

@Injectable({ providedIn: 'root' })
export class ConfirmableActionService {
  private message$: Subject<ConfirmableAction<any>>;

  constructor() {
    this.message$ = new Subject<ConfirmableAction<any>>();
  }

  get message() {
    return this.message$;
  }

  confirm<T>(message: string, payload: T | null = null, unrevertable = false) {
    console.log(message);
    const confirmation = new Subject<Confirmation<T>>();
    this.message$.next({ message, payload, confirmation, unrevertable });
    return confirmation.pipe(take(1));
  }
}
