import { Pipe, PipeTransform, inject } from '@angular/core';
import { LogType } from '@peca/backoffice/models/logs/log-type.model';
import { AnalyticsService } from '@peca/commons';

@Pipe({
  standalone: true,
  name: 'logType',
})
export class LogTypePipe implements PipeTransform {
  anylitics: AnalyticsService;

  constructor() {
    this.anylitics = inject(AnalyticsService);
  }

  transform(type: LogType) {
    switch (type) {
      case 'UPDATE_STATUS':
        return 'Mudança de Status';
      case 'REMOVE_PRODUCT':
        return 'Remoção de Produto';
      case 'UPDATE_PRODUCT':
        return 'Alteração de Produto';
      case 'UPDATE_PRICE':
        return 'Alteração de Preços';
      case 'PARTIAL_ORDER':
        return 'Pedido Complenentar';
      default:
        this.anylitics.callback('logtype_unknown', { type });
        return type;
    }
  }
}
