import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { ModalComponent } from '@peca/design-system';
import { ToastService } from '@peca/commons';
import { HttpClient } from '@angular/common/http';
import { ConfirmableActionService } from '@peca/backoffice/services/confirmable-action.service';
import { Delivery } from '../../models/delivery.model';
import { FreightService } from '../../services/freight.service';
import { FreightRequestForm } from '../../forms/freight-request.form';
import { BehaviorSubject } from 'rxjs';
import { DeliveryType } from '../../models/delivery-type.model';

@Component({
  selector: 'peca-delivery-aggregation',
  templateUrl: './delivery-aggregation.component.html',
  styleUrls: ['./delivery-aggregation.component.scss'],
})
export class DeliveryAggregationComponent {
  @Output()
  success = new EventEmitter<void>();
  @ViewChild('modal')
  modal!: ModalComponent;
  http = inject(HttpClient);
  toast = inject(ToastService);
  confirmable = inject(ConfirmableActionService);
  service: FreightService;
  form: FreightRequestForm;
  deliveryTypes$: BehaviorSubject<DeliveryType[]>;

  constructor() {
    this.service = inject(FreightService);
    this.form = new FreightRequestForm();
    this.deliveryTypes$ = new BehaviorSubject<DeliveryType[]>([]);
  }

  close() {
    this.form.clear();
    this.modal.close();
  }

  open(deliveries: Delivery[]) {
    for (const delivery of deliveries) {
      this.form.pushFreightId(delivery.id);
      this.form.pushOrAggregateOrigin(delivery);
      this.form.pushOrAggregateDestination(delivery);
    }

    const deliveryTypeId = deliveries[0].carrier.id;
    this.service.fetchDeliveryTypes(deliveryTypeId).subscribe((types) => this.deliveryTypes$.next(types));
    this.modal.open();
  }

  onDismissModal() {
    this.form.clear();
  }

  onClickCancel() {
    this.form.clear();
    this.close();
  }

  onSubmit() {
    if (this.form.value.manualShipping) {
      const message = 'Integrações manuais não serão rastreadas pelo sistema.';
      this.confirmable.confirm(message, null, true).subscribe((confirmation) => {
        if (!confirmation.confirmed) return;
        this.requestShipping();
      });
    } else {
      this.requestShipping();
    }
  }

  onClickMoveUp(index: number, key: 'origins' | 'destinations') {
    if (key === 'origins') this.form.moveUpOrigin(index);
    else this.form.moveUpDestination(index);
  }

  onClickMoveDown(index: number, key: 'origins' | 'destinations') {
    if (key === 'origins') this.form.moveDownOrigin(index);
    else this.form.moveDownDestination(index);
  }

  requestShipping() {
    const payload = this.form.value;
    this.service.createShippingRequest(payload).subscribe({
      next: () => {
        this.toast.success('Entrega solicitada com sucesso.');
        this.form.clear();
        this.success.next();
      },
      error: (e: unknown) => {
        console.error(e);
        this.toast.failure('Falha ao solicitar entrega.');
      },
    });
  }
}
