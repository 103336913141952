import { Pipe, PipeTransform } from '@angular/core';
import { OrderInvoiceStatus } from '../models/order-invoice-status.model';

@Pipe({
  name: 'orderInvoiceStatus',
})
export class OrderInvoiceStatusPipe implements PipeTransform {
  transform(value: OrderInvoiceStatus, color = false) {
    switch (value) {
      case 'PENDING':
        return color ? '#CAD3C8' : 'Solicitado';
      case 'PROCESSING':
        return color ? '#FEA47F' : 'Processando';
      case 'ISSUED':
        return color ? '#9AECDB' : 'Emitido';
      case 'FAILED':
        return color ? '#ff948a' : 'Falha';
    }
  }
}
