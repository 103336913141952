<pds-module-header title="Compras" ctaPath="/compras" ctaLabel="Voltar" modifier="outlined">
  <pds-new-breadcrumb [items]="breadcrumb"></pds-new-breadcrumb>

  <ng-container *ngIf="order$ | async; let order">
    <ng-container *ngIf="order.loading">
      <strong>carregando...</strong>
    </ng-container>

    <ng-container *ngIf="order.failure">
      <peca-retry context="detalhes da ordem de compra" (retry)="onRetry()" />
    </ng-container>

    <ng-container *ngIf="!order.loading && !order.failure && order.data">
      <div class="order-details-card">
        <div class="order-details-card__header">
          <div class="order-details-card__header__col --part-number">{{ order.data.order.commerceReference }}</div>
          <div class="order-details-card__header__col --status">
            <pds-chip [content]="order.data.status | status" />
          </div>
          <div class="order-details-card__header__col --datetime">{{ order.data.audit?.createdAt | date : 'dd/MM/yyyy HH:mm' }}</div>
          <div class="order-details-card__header__col --value">{{ order.data.amount | currency : 'BRL' }}</div>
        </div>

        <div class="order-details-card__content">
          <peca-purchase-order-details-summary [order]="order.data" />

          <pds-tabs [tabs]="tabs" (selected)="onTabSelected($event)">
            <router-outlet />
          </pds-tabs>
        </div>
      </div>

      <hr />

      <pds-form-actions>
        <pds-button modifier="outlined">cancelar</pds-button>
        <pds-button icon="upload" (click)="onClickAddNfe()">anexar NFe</pds-button>
        <input #nfeInput type="file" accept="application/pdf, text/xml" (change)="onChangeNfe($event)" />
      </pds-form-actions>
    </ng-container>
  </ng-container>
</pds-module-header>
