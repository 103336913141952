import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { SellerQuery } from '../../services/seller-query.model';

@Component({
  selector: 'peca-seller-list-search',
  templateUrl: './seller-list-search.component.html',
  styleUrls: ['./seller-list-search.component.scss'],
})
export class SellerListSearchComponent {
  @Output()
  readonly filter: EventEmitter<SellerQuery>;

  readonly form: FormGroup;

  constructor() {
    this.filter = new EventEmitter<SellerQuery>();
    this.form = new FormGroup({
      search: new FormControl(''),
      salesModel: new FormControl(''),
      active: new FormControl(''),
      sort: new FormControl('audit.createdAt,asc'),
    });
  }

  onSubmitSearch() {
    this.filter.emit(this.form.value);
  }
}
