<pds-module-header class="order-list" title="Devoluções" ctaLabel="Voltar" modifier="outlined" ctaPath="/devolucoes">
  <pds-new-breadcrumb [items]="breadcrumb" />

  <ng-container *ngIf="order.state$ | async; let order">
    <ng-container *ngIf="order.data">
      <div class="order-details-card">
        <div class="order-details-card__header">
          <div class="order-details-card__header__col --part-number">{{ order.data.order.commerceReference }}</div>
          <div class="order-details-card__header__col --status">
            <strong>Devolução</strong>
            <span pdsChip>{{ order.data.status | status }}</span>
          </div>
          <div class="order-details-card__header__col --status">
            <strong>Pagamento do estorno</strong>
            <span pdsChip>{{ order.data.payment | status }}</span>
          </div>
          <div class="order-details-card__header__col --datetime">{{ order.data.audit.createdAt | date : 'dd/MM/yyyy HH:mm' }}</div>
        </div>

        <div class="order-details-card__content">
          <div class="order-summary">
            <div class="order-summary__row">
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">NFe de Devolução</h4>
                  <div class="order-summary__headline__value">
                    @if (order.data | invoiceWarning) {
                    <span pdsChip modifiers="danger"><span class="icon">warning</span>NF de devolução pendente</span>
                    } @else {
                    <span pdsChip>{{ order.data.invoice ? 'Nº ' + order.data.invoice : '-' }}</span>
                    }
                  </div>
                </div>
              </div>
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">Pedido de venda</h4>
                  <div class="order-summary__headline__value">
                    <a [routerLink]="['/vendas', order.data.order.id]" pdsChipLink target="_blank">{{ order.data.order.commerceReference }}</a>
                  </div>
                </div>
              </div>
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">NFe Pedido de venda</h4>
                  <div class="order-summary__headline__value">
                    @if (order.data.order.invoice?.nfNumber) {
                    <span pdsChip>{{ order.data.order.invoice?.nfNumber }}</span>
                    } @else {
                    <span>-</span>
                    }
                  </div>
                </div>
              </div>
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">Método de pagamento da venda</h4>
                  <div class="order-summary__headline__value">
                    <span pdsChip>{{ order.data.paymentMethod.name }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="order-summary__row">
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">Un. de negócio</h4>
                  <div class="order-summary__headline__value">{{ order.data.businessUnit }}</div>
                </div>
              </div>
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">Razão Social</h4>
                  <div class="order-summary__headline__value">{{ order.data.buyer.companyName }}</div>
                </div>
              </div>
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">CNPJ</h4>
                  <div class="order-summary__headline__value">{{ order.data.buyer.cnpj }}</div>
                </div>
              </div>
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">Endereço</h4>
                  <div class="order-summary__headline__value">
                    {{ (order.data.buyer.shippingAddress | address) || '-' }}
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <h3>Detalhes da Devolução</h3>

            <div class="order-summary__row">
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">Cliente está com a peça?</h4>
                  <div class="order-summary__headline__value">{{ order.data.customerHavePart ? 'Sim' : 'Não' }}</div>
                </div>
              </div>
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">Categoria da devoluçao</h4>
                  <div class="order-summary__headline__value">{{ order.data.category | category }}</div>
                </div>
              </div>
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">Motivo</h4>
                  <div class="order-summary__headline__value">{{ order.data.reason | reason }}</div>
                </div>
              </div>
              <div class="order-summary__col">
                <div class="order-summary__headline">
                  <h4 class="order-summary__headline__title">Justificativa</h4>
                  <div class="order-summary__headline__value">{{ order.data.justification || '-' }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="order-details-pricing">
            <div class="order-details-pricing__col">
              <div class="order-details-pricing__col__label">Método de estorno</div>
              <div class="order-details-pricing__col__value">
                <span pdsChip>{{ order.data.creditRefund ? 'Crédito em conta corrente' : order.data.paymentMethod.name }}</span>
              </div>
            </div>
            <div class="order-details-pricing__col">
              <div class="order-details-pricing__col__label">Frete</div>
              <div class="order-details-pricing__col__value">{{ order.data.freightRefundAmount || 0 | currency : 'BRL' }}</div>
            </div>
            <div class="order-details-pricing__col">
              <div class="order-details-pricing__col__label">Produtos devolvidos</div>
              <div class="order-details-pricing__col__value">{{ order.data.productsRefundAmount || 0 | currency : 'BRL' }}</div>
            </div>
            <div class="order-details-pricing__col">
              <div class="order-details-pricing__col__label">Valor da devolução</div>
              <div class="order-details-pricing__col__value">{{ order.data.totalRefundAmount | currency : 'BRL' }}</div>
            </div>
          </div>

          <pds-tabs [tabs]="tabs" (selected)="onTabSelected($event)">
            <router-outlet />
          </pds-tabs>

          <div class="order-details-card__content__observations">
            <div class="order-details-card__content__observations__col">
              <h4>Observações internas</h4>
              <p>{{ order.data.observation || '-' }}</p>
            </div>
            <div class="order-details-card__content__observations__col">
              <h4>Imagens anexadas</h4>
              <div class="flex gap-2 wrap">
                @for (file of order.data.files; track $index) {
                <span pdsChip [modifiers]="file.status === 'UPLOADED' ? 'primary-reverse' : 'default'" (click)="onClickPreview(file)">
                  <span class="truncate-text">{{ file.name }}</span>
                  @if (file.status === 'UPLOADED') {
                  <span class="icon">open_in_new</span>
                  } @else {
                  <span class="icon spin">progress_activity</span>
                  }
                </span>
                } @empty {
                <span>-</span>
                }
              </div>
            </div>
          </div>

          <hr />

          <pds-form-actions>
            <button type="button" title="Anaxar imagem" pdsButton (click)="onClickAttach()">Anexar imagem</button>
            <button type="button" title="Alterar detalhes" pdsButton (click)="onClickOpenForm()">Alterar detalhes</button>
          </pds-form-actions>
        </div>
      </div>

      <peca-document-preview #documentPreview (delete)="onDeleteEvent($event)" />
      <peca-orders-return-form-modal #form (submit)="onSubmitForm($event)" />
      <pds-upload #upload (upload)="onUploadFile($event)" />
    </ng-container>

    <ng-container *ngIf="order.loading">
      <strong>carregando...</strong>
    </ng-container>

    <ng-container *ngIf="order.failed">
      <peca-retry context="detalhes da devolução" (retry)="onRetryEvent()" />
    </ng-container>
  </ng-container>
</pds-module-header>
