import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Validators } from '@peca/commons';
import { of } from 'rxjs';

import { B2BRequest } from '../../models/b2b-request.model';
import { B2B } from '../../models/b2b.model';

export class B2BForm {
  readonly group: FormGroup;

  get controls() {
    return {
      active: this.group.get('active') as FormControl,
      id: this.group.get('identification.id') as FormControl,
      buyer: this.group.get('identification.buyer') as FormControl,
      userId: this.group.get('identification.userId') as FormControl,
      clientId: this.group.get('identification.clientId') as FormControl,
      clientSecret: this.group.get('identification.clientSecret') as FormControl,
      showPartNumber: this.group.get('search.showPartNumber') as FormControl,
      faster: this.group.get('search.faster') as FormControl,
      vouchers: this.group.get('payment.vouchers') as FormControl,
      paymentMethodId: this.group.get('payment.paymentMethodId') as FormControl,
      convenienceFee: this.group.get('payment.convenienceFee') as FormControl,
      applyCartStrategy: this.group.get('cart.applyCartStrategy') as FormControl,
      deliveryTime: this.group.get('search.deliveryTime') as FormControl,
      applyDialect: this.group.get('search.applyDialect') as FormControl,
      searchByPlate: this.group.get('searchByPlate') as FormControl,
      vehicleSearchView: this.group.get('vehicleSearchView') as FormControl,
      searchLimit: this.group.get('searchLimit') as FormControl,
    };
  }

  constructor() {
    this.group = new FormGroup({
      active: new FormControl(true),
      cart: new FormGroup({
        applyCartStrategy: new FormControl(true),
      }),
      identification: new FormGroup({
        id: new FormControl(null),
        buyer: new FormControl(null, []),
        userId: new FormControl('', [Validators.required]),
        clientId: new FormControl('', {
          validators: [Validators.required],
          updateOn: 'blur',
        }),
        clientSecret: new FormControl('', []),
      }),
      search: new FormGroup({
        showPartNumber: new FormControl(false, [Validators.required]),
        faster: new FormControl(false, [Validators.required]),
        deliveryTime: new FormControl('', [Validators.required, Validators.number.min(1)]),
        applyDialect: new FormControl(true),
      }),
      payment: new FormGroup({
        vouchers: new FormControl([]),
        paymentMethodId: new FormControl('', [Validators.required]),
        convenienceFee: new FormControl(0, [Validators.required, Validators.number.min(0), Validators.number.max(100)]),
      }),
      searchByPlate: new FormControl(true),
      vehicleSearchView: new FormControl('', [Validators.required]),
      searchLimit: new FormControl(''),
    });
  }

  setValue(b2b: B2B) {
    const [voucher] = b2b.vouchers || [];

    this.group.setValue({
      active: b2b.active,
      identification: {
        id: b2b.id,
        buyer: { id: b2b.buyer.id, label: b2b.buyer.companyName },
        userId: b2b.userId,
        clientId: b2b.clientId,
        clientSecret: b2b.clientSecret || '',
      },
      search: {
        showPartNumber: b2b.showPartNumber,
        faster: b2b.faster,
        deliveryTime: b2b.deliveryTime,
        applyDialect: b2b.applyDialect,
      },
      cart: {
        applyCartStrategy: b2b.applyCartStrategy,
      },
      payment: {
        vouchers: voucher ? { id: voucher.id, label: voucher.name } : null,
        paymentMethodId: b2b.paymentMethod.id,
        convenienceFee: b2b.convenienceFee,
      },
      searchByPlate: b2b.searchByPlate,
      vehicleSearchView: b2b.vehicleSearchView,
      searchLimit: b2b.searchLimit,
    });
  }

  getValue() {
    let vouchers: string[] = [];

    if (this.controls.vouchers.value) {
      vouchers = [this.controls.vouchers.value.id as string];
    }

    return {
      active: this.controls.active.value,
      id: this.controls.id.value,
      buyer: this.controls.buyer.value,
      userId: this.controls.userId.value,
      clientId: this.controls.clientId.value,
      clientSecret: this.controls.clientSecret.value,
      showPartNumber: this.controls.showPartNumber.value,
      faster: this.controls.faster.value,
      vouchers,
      paymentMethodId: this.controls.paymentMethodId.value,
      convenienceFee: this.controls.convenienceFee.value,
      applyCartStrategy: this.controls.applyCartStrategy.value,
      deliveryTime: this.controls.deliveryTime.value,
      applyDialect: this.controls.applyDialect.value,
      searchByPlate: this.controls.searchByPlate.value,
      vehicleSearchView: this.controls.vehicleSearchView.value,
      searchLimit: this.controls.searchLimit.value,
    } as B2BRequest;
  }

  addBuyerValidators(validate: any) {
    this.controls.buyer.addAsyncValidators((control: AbstractControl) => {
      if (!control.value) return of(null);
      return validate(control.value.id);
    });
  }

  addClientIdValidation(validate: any) {
    this.controls.clientId.addAsyncValidators((control: AbstractControl) => {
      if (!control.value || control.pristine) return of(null);
      return validate(control.value);
    });
  }
}
