import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Confirmable, ToastService } from '@peca/commons';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { VoucherService } from '../../services/voucher.service';
import { VoucherQuery } from '../../models/voucher-request.model';
import {
  Pagination,
  PaginationInitialState,
  BreadcrumbItem,
} from '@peca/design-system';
import { Pageable } from '../../../../core/models/pageable.model';
import { AsyncState } from '../../../../core/models/async-state.model';
import { Voucher } from '../../models/voucher.model';
import { FormControl, FormGroup } from '@angular/forms';
import { ConfirmableActionService } from '../../../../core/services/confirmable-action.service';

@Component({
  selector: 'peca-vouchers-list',
  templateUrl: './vouchers-list.component.html',
  styleUrls: ['./vouchers-list.component.scss'],
})
export class VouchersListComponent implements OnInit {
  private readonly destroyRef$ = new Subject<void>();
  private readonly router = inject(Router);
  private readonly toast = inject(ToastService);
  private readonly service: VoucherService;
  private readonly confirmable = inject(ConfirmableActionService);
  readonly breadcrumbItems: BreadcrumbItem[] = [
    { label: 'Listagem de Cupons', path: '/cupons' },
  ];

  readonly vouchers$ = new BehaviorSubject<AsyncState<Pageable<Voucher>>>({
    loading: true,
  });
  readonly pagination$ = new BehaviorSubject<Pagination>(
    PaginationInitialState
  );

  readonly toggleStatusConfirmable$ = new Subject<Confirmable>();

  constructor() {
    this.service = inject(VoucherService);
  }

  ngOnInit() {
    this.fetch(this.lastQuery);
  }

  lastQuery: VoucherQuery = { sort: 'id,asc', ...PaginationInitialState };

  handlePaginateEvent(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetch(query);
  }

  fetch(query: VoucherQuery) {
    this.lastQuery = query;
    this.service
      .fetch(this.lastQuery)
      .pipe(takeUntil(this.destroyRef$))
      .subscribe({
        next: (data) => {
          this.paginate(data);
          this.vouchers$.next({ data });
        },
        error: () => {
          this.vouchers$.next({ failure: true });
        },
      });
  }

  paginate(page: Pageable<unknown>) {
    this.pagination$.next({
      page: page.currentPage + 1,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    });
  }

  onChangeVoucherStatus(voucher: Voucher) {
    this.confirmable
      .confirm('Você está alterando o status de um cupom.')
      .subscribe((confirmation) => {
        if (!confirmation.confirmed) {
          return (voucher.active = !voucher.active);
        }

        this.service
          .updateStatus(voucher.id, voucher.active)
          .pipe(takeUntil(this.destroyRef$))
          .subscribe({
            error: (err) => {
              console.error(err);
              voucher.active = !voucher.active;
              this.toast.failure(
                'Ocorreu um erro ao alterar o status do cupom'
              );
            },
            complete: () => {
              this.toast.success('Status do cupom alterado com sucesso');
            },
          });
        return;
      });
  }

  onClickDetails(voucher: Voucher) {
    this.router.navigate(['/cupons', voucher.id]);
  }

  onSubmitSearch() {
    if (this.searchForm.invalid) {
      return this.toast.failure('Filtro incorreto');
    }
    const { active, search, campaignDate } = this.searchForm.value;
    const query = { ...this.lastQuery, search, active, campaignDate };

    this.fetch(query as VoucherQuery);
  }

  searchForm = new FormGroup({
    search: new FormControl(''),
    campaignDate: new FormControl(''),
    active: new FormControl(''),
  });
}
