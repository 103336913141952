import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[pdsPanel]',
})
export class PanelDirective {
  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    this.renderer.addClass(this.elementRef.nativeElement, 'pds-panel');
  }
}
