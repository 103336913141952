import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { AsyncState } from '@peca/backoffice/models/async-state.model';
import { BreadcrumbItem, KeyTab, Tab } from '@peca/design-system';
import { BehaviorSubject } from 'rxjs';
import { PurchaseOrder } from '../../models/purchase-order.model';
import { PurchaseOrderService } from '../../services/purchase.service';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@peca/commons';
import { AnalyticsService } from '@peca/commons';

type State = AsyncState<PurchaseOrder>;

@Component({
  templateUrl: './purchase-order-details.component.html',
  styleUrls: ['./purchase-order-details.component.scss'],
})
export class PurchaseOrderDetailsComponent implements OnInit {
  @ViewChild('nfeInput')
  nfeInput!: ElementRef;
  order$: BehaviorSubject<State>;
  breadcrumb: BreadcrumbItem[];
  tabs: KeyTab;
  service: PurchaseOrderService;
  route: ActivatedRoute;
  toast: ToastService;
  analytics: AnalyticsService;

  constructor() {
    this.breadcrumb = [{ label: 'Compras', path: '/compras' }];
    this.order$ = new BehaviorSubject<State>({ loading: true });
    this.tabs = {
      products: { label: 'Produtos', path: 'produtos', active: true, roles: [] },
      logs: { label: 'Logs', path: 'logs', roles: [] },
    };
    this.service = inject(PurchaseOrderService);
    this.route = inject(ActivatedRoute);
    this.toast = inject(ToastService);
    this.analytics = inject(AnalyticsService);
  }

  get selectedTab() {
    const tab = Object.values<Tab>(this.tabs).find(({ active }) => active);
    if (!tab) return this.tabs[0];
    return tab;
  }

  get id() {
    return this.route.snapshot.params['id'] as string;
  }

  ngOnInit() {
    this.analytics.view('purchase_details', { id: this.id });
    this.fetchOrder(this.id);
  }

  fetchOrder(id: string) {
    this.order$.next({ loading: true });
    this.service.fetchOrderDetails(id).subscribe({
      next: (order) => {
        this.order$.next({ data: order });
        this.breadcrumb.push({ label: order.order.commerceReference });
      },
      error: () => this.order$.next({ failure: true }),
    });
  }

  onTabSelected(tab: Tab) {
    this.selectedTab.active = false;
    tab.active = true;
  }

  onClickAddNfe() {
    this.nfeInput.nativeElement.click();
  }

  onChangeNfe(event: Event) {
    const { files } = event.target as HTMLFormElement;

    if (!files) return;

    const [file] = files;
    const formData = new FormData();
    const order = this.order$.value.data;

    if (!order) return;

    formData.append('file', file);
    this.service.uploadNfe(order.order.id, order.id, formData).subscribe({
      next: () => {
        this.ngOnInit();
        this.toast.success('NFe anexado com sucesso.');
      },
      error: () => {
        this.toast.failure('Falha ao anexar NFe.');
      },
    });
  }

  onRetry() {
    this.fetchOrder(this.id);
  }
}
