<ng-container *ngIf="pagination.total > 1">
  <div class="pds-pagination">
    <div class="pds-pagination__page">
      <span>Exibindo</span>
      <select
        class="pds-pagination__page__input"
        [disabled]="!!pagination.disabled"
        [(ngModel)]="sizeControl"
        (change)="handleSizeValueChanges($event)"
      >
        <ng-container *ngFor="let size of acceptedSizes">
          <option [value]="size">{{ size }}</option>
        </ng-container>
      </select>
      <span>itens por página. Página</span>
      <input
        type="number"
        min="1"
        class="pds-pagination__page__input"
        [disabled]="!!pagination.disabled"
        [max]="pagination.total"
        [(ngModel)]="pageControl"
        (change)="handlePageValueChanges($event)"
      />
      <span>de {{ pagination.total }}</span>
    </div>
    <div class="pds-pagination__controls">
      <button class="pds-pagination__controls__control" [disabled]="disabledPreviousPage" (click)="handleFirstPageEventClick()">
        <pds-icon>first_page</pds-icon>
      </button>
      <button class="pds-pagination__controls__control" [disabled]="disabledPreviousPage" (click)="handlePreviousPageEventClick()">
        <pds-icon>navigate_before</pds-icon>
      </button>
      <button class="pds-pagination__controls__control" [disabled]="disabledNextPage" (click)="handleNextPageEventClick()">
        <pds-icon>navigate_next</pds-icon>
      </button>
      <button class="pds-pagination__controls__control" [disabled]="disabledNextPage" (click)="handleLastPageEventClick()">
        <pds-icon>last_page</pds-icon>
      </button>
    </div>
  </div>
</ng-container>
