import { BehaviorSubject, Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { Pagination, PaginationInitialState, BreadcrumbItem } from '@peca/design-system';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Confirmable, ToastService } from '@peca/commons';

import { AsyncState } from '../../../../core/models/async-state.model';
import { Pageable } from '../../../../core/models/pageable.model';
import { CarrierService } from '../../services/carrier.service';
import { Carrier } from '../../models/carrier.model';
import { CarrierQuery } from '../../models/carrier-query.model';

@Component({
  selector: 'peca-carriers-list',
  templateUrl: './carriers-list.component.html',
  styleUrls: ['./carriers-list.component.scss'],
})
export class CarriersListComponent implements OnInit, OnDestroy {
  readonly destroyRef$: Subject<void>;
  readonly service: CarrierService;
  readonly carriers$: BehaviorSubject<AsyncState<Array<Carrier>>>;
  readonly pagination$: BehaviorSubject<Pagination>;
  readonly router: Router;
  readonly changeStatusConfirmable$: Subject<Confirmable>;
  readonly toast: ToastService;
  readonly breadcrumbItems: BreadcrumbItem[] = [{ label: 'Listagem de Transportadoras', path: '/transportadoras' }];

  lastQuery: CarrierQuery;

  constructor() {
    this.destroyRef$ = new Subject<void>();
    this.service = inject(CarrierService);
    this.carriers$ = new BehaviorSubject<AsyncState<Array<Carrier>>>({
      loading: true,
    });
    this.lastQuery = { sort: 'id,asc', ...PaginationInitialState };
    this.pagination$ = new BehaviorSubject<Pagination>(PaginationInitialState);
    this.router = inject(Router);
    this.changeStatusConfirmable$ = new Subject<Confirmable>();
    this.toast = inject(ToastService);
  }

  ngOnInit() {
    const query = this.lastQuery;
    this.fetchCarriers(query);
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  paginate(page: Pageable<unknown>) {
    this.pagination$.next({
      page: page.currentPage + 1,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    });
  }

  fetchCarriers(query: CarrierQuery) {
    this.carriers$.next({ loading: true });
    this.service
      .fetchCarriers(query)
      .pipe(takeUntil(this.destroyRef$), distinctUntilChanged())
      .subscribe({
        next: this.onFetchCarriersSuccess.bind(this),
        error: this.onFetchCarriersFailure.bind(this),
      });
  }

  onFetchCarriersSuccess(response: Pageable<Carrier>) {
    this.paginate(response);
    this.carriers$.next({ data: response.items });
  }

  onFetchCarriersFailure(e: unknown) {
    console.error(JSON.stringify(e));
    this.carriers$.next({ failure: true });
  }

  updateStatus(carrier: Carrier) {
    const { id, active } = carrier;
    this.service
      .updateStatus(id, active)
      .pipe(takeUntil(this.destroyRef$))
      .subscribe({
        next: this.onUpdateStatusSuccess.bind(this),
        error: (e) => this.onUpdateStatusFailure(e, carrier),
      });
  }

  onUpdateStatusSuccess() {
    this.toast.success('O status da transportadora foi alterado com sucesso.');
  }

  onUpdateStatusFailure(e: unknown, carrier: Carrier) {
    const message = 'Ocorreu um erro ao alterar o status da transportadora.';
    console.error(JSON.stringify(e));
    this.toast.failure(message);
    carrier.active = !carrier.active;
  }

  handleSearchEvent(query: CarrierQuery) {
    this.fetchCarriers(query);
  }

  handleStatusChanges(carrier: Carrier, event: boolean) {
    carrier.active = event;
    this.changeStatusConfirmable$.next({ confirmed: false, payload: carrier });
  }

  handleDetailsClickEvent(carrier: Carrier) {
    this.router.navigate(['/transportadoras', carrier.id]);
  }

  handleConfirmStatusChange(confirmable: Confirmable) {
    const carrier = confirmable.payload as Carrier;

    if (!confirmable.confirmed) {
      carrier.active = !carrier.active;
      return;
    }

    this.updateStatus(carrier);
  }

  onRetry() {
    this.ngOnInit();
  }
}
