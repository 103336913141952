import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { InsightsService } from '@peca/design-system';

@Component({
  selector: 'peca-main-navigation-insights',
  templateUrl: './main-navigation-insights.component.html',
  styleUrls: ['./main-navigation-insights.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class MainNavigationInsightsComponent implements OnInit {
  expanded: boolean;
  insightsServices: InsightsService;

  constructor() {
    this.expanded = false;
    this.insightsServices = inject(InsightsService);
  }

  get insights$() {
    return this.insightsServices.insights;
  }

  ngOnInit(): void {
    this.insights$.subscribe((insights: unknown[]) => (this.expanded = !!insights.length));
  }

  onClickToogleSidebar() {
    this.expanded = !this.expanded;
  }
}
