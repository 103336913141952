import { Directive, ElementRef, inject, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: 'button[pdsButton]',
})
export class PDSButtonDirective implements OnInit {
  @Input()
  modifiers: string;
  elementRef: ElementRef;
  renderer: Renderer2;

  constructor() {
    this.modifiers = 'primary';
    this.elementRef = inject(ElementRef);
    this.renderer = inject(Renderer2);
  }

  get hostElement() {
    return this.elementRef.nativeElement;
  }

  ngOnInit() {
    const modifiers = this.modifiers
      .split(' ')
      .map((modifier) => `--is-${modifier}`)
      .join(' ');

    this.renderer.addClass(this.hostElement, 'pds-button');
    this.renderer.addClass(this.hostElement, modifiers);
  }
}
