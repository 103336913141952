import { Subject, map, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Async } from '@peca/backoffice/utils/async.util';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AnalyticsService } from '@peca/commons';

import { OrderFreight } from '../../../models/order-freight.model';
import { OrderService } from '../../../services/order.service';

@Component({
  templateUrl: './order-details-deliveries.component.html',
  styleUrls: ['./order-details-deliveries.component.scss'],
})
export class OrderDetailsDeliveriesComponent implements OnInit, OnDestroy {
  service: OrderService;
  route: ActivatedRoute;
  destroyRef$: Subject<void>;
  freights: Async<OrderFreight[]>;
  analytics: AnalyticsService;

  constructor() {
    this.service = inject(OrderService);
    this.route = inject(ActivatedRoute);
    this.analytics = inject(AnalyticsService);
    this.destroyRef$ = new Subject();
    this.freights = new Async();
  }

  get freights$() {
    return this.freights.state$;
  }

  ngOnInit() {
    this.analytics.view('order-details-deliveries');
    this.route.parent?.params
      .pipe(
        takeUntil(this.destroyRef$),
        map((params) => params['orderId'] as string)
      )
      .subscribe(this.fetchOrderFreights.bind(this));
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  fetchOrderFreights(orderId: string) {
    this.freights.loading();
    this.service.fetchOrderFreights(orderId).subscribe({
      next: (freights) => this.freights.loaded(freights),
      error: () => this.freights.failed(),
    });
  }

  onClickToggleProducts(freight: OrderFreight) {
    freight.expanded = !freight.expanded;
  }
}
