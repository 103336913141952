import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OrdersReturnQuery } from '../../models/orders-return-search.model';
import { PersistQueryService } from '@peca/backoffice/services/persist-query.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'peca-orders-return-search',
  templateUrl: './orders-return-search.component.html',
  styleUrls: ['./orders-return-search.component.scss'],
})
export class OrdersReturnSearchComponent implements OnInit {
  @Output()
  search: EventEmitter<OrdersReturnQuery>;
  form: FormGroup;
  statusOptions: { id: string; label: string }[];
  paymentOptions: { id: string; label: string }[];
  persistQueryService: PersistQueryService;
  route: ActivatedRoute;

  constructor() {
    this.route = inject(ActivatedRoute);
    this.persistQueryService = inject(PersistQueryService);
    this.search = new EventEmitter<OrdersReturnQuery>();
    this.form = new FormGroup({
      search: new FormControl(null),
      date: new FormControl(null),
      status: new FormControl([]),
      payment: new FormControl([]),
    });
    this.statusOptions = [
      { id: 'PROCESSING', label: 'Processando' },
      { id: 'PENDING_COLLECTION', label: 'Pendente de coleta' },
      { id: 'IN_TRANSIT', label: 'Em trânsito' },
      { id: 'UNDER_ANALYSIS', label: 'Em análise' },
      { id: 'APPROVED', label: 'Aprovado' },
      { id: 'REJECTED', label: 'Rejeitado' },
    ];
    this.paymentOptions = [
      { id: 'PENDING', label: 'Pendente' },
      { id: 'PAID', label: 'Pago' },
      { id: 'NOT_PAID', label: 'Não pago' },
      { id: 'CANCELED', label: 'Estorno cancelado' },
    ];
  }

  get controls() {
    return {
      search: this.form.get('search') as FormControl,
      date: this.form.get('date') as FormControl,
      status: this.form.get('status') as FormControl,
      payment: this.form.get('payment') as FormControl,
    };
  }

  get value() {
    return {
      search: this.controls.search.getRawValue() || null,
      startDate: this.controls.date.getRawValue()?.startDate || null,
      endDate: this.controls.date.getRawValue()?.endDate || null,
      status: this.controls.status.getRawValue() || null,
      payment: this.controls.payment.getRawValue() || null,
    };
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (!params['q']) return;
      const { search, status, startDate, endDate, payment } = JSON.parse(atob(params['q']));
      this.form.patchValue({ search, status, date: { startDate, endDate }, payment });
    });
  }

  onClickSearch() {
    this.search.emit(this.value);
  }
}
