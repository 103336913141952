<section class="pds-module-header">
  <div class="pds-module-header__header">
    <div class="pds-module-header__header__title">
      <h1>{{ title }}</h1>
    </div>

    <ng-container *ngIf="ctaPath">
      <pds-link [path]="ctaPath" [modifier]="modifier || 'primary'">
        {{ ctaLabel }}
      </pds-link>
    </ng-container>
  </div>

  <ng-content></ng-content>
</section>
