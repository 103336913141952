<pds-modal #modal title="Editar Valores" [width]="1024" (dismiss)="close()">
  <p class="lead-text mb-12">Altere os valores unitários dos produtos e do frete:</p>

  <ng-container *ngIf="products.state$ | async; let products">
    @if (products.data) {
    <pds-table>
      <table>
        <thead>
          <tr>
            <th rowspan="2">Vendedor</th>
            <th colspan="2">Quantidade</th>
            <th rowspan="2">Nome</th>
            <th rowspan="2">Part number</th>
            <th rowspan="2">Marca</th>
            <th rowspan="2">Valor unitário</th>
            <th rowspan="2">Desconto unitário</th>
            <th rowspan="2">Valor total</th>
          </tr>
          <tr>
            <th>Adiquirida</th>
            <th>Devolvida</th>
          </tr>
        </thead>
        <tbody>
          @for (product of products.data; track $index) {
          <tr>
            <td>
              <div class="flex flex-column gap-4">
                <strong>{{ product.branch.tradingName }}</strong>
                <small>{{ product.branch.commercialName }} ({{ product.branch.externalReference }})</small>
              </div>
            </td>
            <td>{{ product.quantity }}</td>
            <td>{{ product.returnedQuantity || '-' }}</td>
            <td>{{ product.description }}</td>
            <td>{{ product.partNumber }}</td>
            <td>{{ product.brand }}</td>
            <td><input pdsFormControl type="text" [(ngModel)]="product.unitPrice" min="0" style="width: 100px" pdsNumberMask /></td>
            <td>{{ product.unitDiscountPrice | currency : 'BRL' }}</td>
            <td>{{ product.totalPrice | currency : 'BRL' }}</td>
          </tr>
          }
        </tbody>
      </table>
    </pds-table>

    <div *ngIf="order" class="order-details-pricing mb-12">
      <div class="order-details-pricing__col">
        <div class="order-details-pricing__col__label">Produtos</div>
        <div class="order-details-pricing__col__value">{{ order | orderPricing : 'PRODUCTS' | currency : 'BRL' }}</div>
      </div>
      <div class="order-details-pricing__col">
        <div class="order-details-pricing__col__label">Frete</div>
        <div class="order-details-pricing__col__value">
          <td><input pdsFormControl type="text" [(ngModel)]="order.freightAmount" min="0" style="width: 100px" pdsNumberMask /></td>
        </div>
      </div>
      <div class="order-details-pricing__col">
        <div class="order-details-pricing__col__label">Sub total</div>
        <div class="order-details-pricing__col__value">{{ order | orderPricing : 'SUBTOTAL' | currency : 'BRL' }}</div>
      </div>
      <div class="order-details-pricing__col">
        <div class="order-details-pricing__col__label">Descontos</div>
        <div class="order-details-pricing__col__value">{{ order | orderPricing : 'DISCOUNTS' | currency : 'BRL' }}</div>
      </div>
      <div class="order-details-pricing__col">
        <div class="order-details-pricing__col__label">Total</div>
        <div class="order-details-pricing__col__value">{{ order | orderPricing : 'TOTAL' | currency : 'BRL' }}</div>
      </div>
    </div>
    } @else if (products.loading) {
    <strong>carregando...</strong>
    } @else {
    <strong>Falha ao carregar produtos.</strong>
    }
  </ng-container>

  <pds-form-actions>
    <button pdsButton modifiers="outlined" title="Cancelar" (click)="onClickCancel()">Cancelar</button>
    <button pdsButton title="Salvar valores" (click)="onClickSubmit()">Salvar valores</button>
  </pds-form-actions>
</pds-modal>
