<span class="pds-chip --is-{{ modifier }} --{{ status }}" [ngStyle]="{ 'background-color': colorHex }">
  <ng-container *ngIf="content; else contentProjection">
    <ng-container *ngIf="target; else noTargetTemplate">
      <a [href]="target" target="_blank">
        <span>{{ content }}</span>
        <span class="icon">download</span>
      </a>
    </ng-container>

    <ng-template #noTargetTemplate>
      {{ content }}
    </ng-template>
  </ng-container>
  <ng-template #contentProjection />
</span>
