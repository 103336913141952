import { Component, Input } from '@angular/core';

@Component({
  selector: 'pds-icon-button[icon]',
  styleUrls: ['./icon-button.component.scss'],
  template: `
    <button class="pds-icon-button" type="button" [disabled]="disabled" [title]="title">
      <span class="icon">{{ icon }}</span>
    </button>
  `,
})
export class IconButtonComponent {
  @Input()
  icon!: string;

  @Input()
  disabled: boolean;

  @Input()
  title: string;

  constructor() {
    this.disabled = false;
    this.title = 'Botão';
  }
}
