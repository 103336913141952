import { Component, Input } from '@angular/core';

@Component({
  selector: 'pds-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent {
  @Input()
  content: unknown | undefined;

  @Input()
  modifier = 'default';

  @Input('color')
  colorHex: string | undefined;

  @Input()
  target: string | undefined;

  @Input()
  status = '';
}
