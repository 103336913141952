import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

interface BreadcrumbItem {
  text: string;
  path: string;
}

@Component({
  selector: 'pds-breadcrumb',
  template: `
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li
          class="breadcrumb-item"
          *ngFor="let breadcrumb of breadcrumbs; let last = last"
          [ngClass]="{ active: last }"
        >
          <a *ngIf="!last" [routerLink]="breadcrumb.path">{{
            breadcrumb.text
          }}</a>
          <span *ngIf="last">{{ breadcrumb.text }}</span>
        </li>
      </ol>
    </nav>
  `,
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: BreadcrumbItem[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.updateBreadcrumbs();
  }

  private updateBreadcrumbs(): void {
    const url = this.router.url;
    this.breadcrumbs = this.createBreadcrumbs(url);
  }

  private createBreadcrumbs(url: string): BreadcrumbItem[] {
    const segments = url.split('/').slice(1);
    const breadcrumbs: BreadcrumbItem[] = [];
    let accumulatedPath = '';

    segments.forEach((segment) => {
      if (!segment) return;
      accumulatedPath += `/${segment}`;
      breadcrumbs.push({ text: segment, path: accumulatedPath });
    });

    return breadcrumbs;
  }
}
