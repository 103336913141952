<div [class.--is-disabled]="disabled" class="checkbox-container" (click)="toggle()">
  <input type="checkbox" [checked]="value" [disabled]="disabled" />

  <pds-icon [class.--is-disabled]="disabled">
    <ng-container *ngIf="value; else unchecked">check_box</ng-container>
    <ng-template #unchecked>check_box_outline_blank</ng-template>
  </pds-icon>

  <div class="checkbox-container__label">
    <ng-container *ngIf="label">
      <label>{{ label }}</label>

      <ng-container *ngIf="sublabel">
        <small>{{ sublabel }}</small>
      </ng-container>
    </ng-container>
  </div>
</div>
