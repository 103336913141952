import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';

import { Confirmable, ToastService } from '@peca/commons';
import {
  Pagination,
  PaginationInitialState,
  BreadcrumbItem,
} from '@peca/design-system';

import { B2BService } from '../../services/b2b.service';
import { AsyncState } from '../../../../core/models/async-state.model';
import { Pageable } from '../../../../core/models/pageable.model';
import { B2B } from '../../models/b2b.model';
import { B2BQuery } from '../../models/b2b-query.model';

@Component({
  selector: 'peca-b2b-list',
  templateUrl: './b2b-list.component.html',
  styleUrls: ['./b2b-list.component.scss'],
})
export class B2bListComponent implements OnInit {
  private router = inject(Router);

  private readonly service = inject(B2BService);

  private readonly toast = inject(ToastService);

  readonly pagination$ = new BehaviorSubject<Pagination>(
    PaginationInitialState
  );

  readonly searchForm = new FormGroup({
    search: new FormControl(''),
    active: new FormControl(''),
  });

  readonly b2bs$ = new BehaviorSubject<AsyncState<Pageable<B2B>>>({
    loading: true,
  });

  readonly changeB2BStatusConfirmable$ = new Subject<Confirmable>();

  lastQuery: B2BQuery = { sort: 'id,asc', ...PaginationInitialState };

  readonly breadcrumbItems: BreadcrumbItem[] = [
    { label: 'Listagem de B2B', path: '/b2b' },
  ];

  ngOnInit() {
    this.fetch(this.lastQuery);
  }

  fetch(query: B2BQuery) {
    this.lastQuery = query;
    this.service.fetch(this.lastQuery).subscribe({
      next: (data) => {
        this.paginate(data);
        this.b2bs$.next({ data });
      },
      error: () => {
        this.b2bs$.next({ failure: true });
      },
    });
  }

  updateStatus(b2bId: number, active: boolean) {
    this.service.updateStatus(b2bId, active).subscribe({
      next: () => this.toast.success('Status alterado com sucesso'),
      error: () => this.toast.failure('Falha ao alterar o status.'),
    });
  }

  paginate(page: Pageable<unknown>) {
    this.pagination$.next({
      page: page.currentPage + 1,
      size: page.pageSize,
      total: page.totalPages,
      items: page.totalItems,
    });
  }

  onSubmitSearch() {
    if (this.searchForm.invalid) return;

    const { search, active } = this.searchForm.value;
    const query = { ...this.lastQuery, search, active };

    this.fetch(query as B2BQuery);
  }

  onChangeB2BStatus(b2b: B2B) {
    this.changeB2BStatusConfirmable$.next({
      confirmed: false,
      payload: b2b,
    });
  }

  onConfirmB2BStatusChanges(confirmable: Confirmable) {
    const b2b = confirmable.payload as B2B;

    if (!confirmable.confirmed) {
      b2b.active = !b2b.active;
      return;
    }

    this.updateStatus(b2b.id, b2b.active);
  }

  onPaginate(pagination: Pagination) {
    const page = pagination.page ? pagination.page - 1 : 0;
    const query = { ...this.lastQuery, ...pagination, page };
    this.fetch(query);
  }

  onClickDetails(b2b: B2B) {
    this.router.navigate(['/b2b', b2b.id]);
  }
}
