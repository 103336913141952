import { Directive, ElementRef, inject, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: 'a[pdsChipLink]',
})
export class PDSChipLinkDirective implements OnInit {
  @Input()
  modifiers: string;
  @Input()
  color: string | null;
  elementRef: ElementRef;
  renderer: Renderer2;

  constructor() {
    this.modifiers = 'default';
    this.color = null;
    this.elementRef = inject(ElementRef);
    this.renderer = inject(Renderer2);
  }

  get hostElement() {
    return this.elementRef.nativeElement;
  }

  ngOnInit() {
    const icon = this.renderer.createElement('span');
    const modifiers = this.modifiers
      .split(' ')
      .map((mod) => `--is-${mod}`)
      .join(' ');

    if (this.color) {
      this.renderer.setStyle(this.hostElement, 'background-color', this.color);
    }

    this.renderer.addClass(icon, 'icon');
    this.renderer.appendChild(icon, this.renderer.createText('open_in_new'));
    this.renderer.addClass(this.hostElement, 'pds-chip-link');
    this.renderer.addClass(this.hostElement, modifiers);
    this.renderer.appendChild(this.hostElement, icon);
  }
}
