import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { KeyTab, Tab } from './tabs.model';

@Component({
  selector: 'pds-tabs[tabs]',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
  @Input('tabs')
  _tabs: Tab[] | KeyTab;

  @Output()
  selected: EventEmitter<Tab>;

  constructor() {
    this._tabs = [];
    this.selected = new EventEmitter<Tab>();
  }

  get tabs() {
    if (Array.isArray(this._tabs)) return this._tabs;
    return Object.values<Tab>(this._tabs);
  }

  handleSelectTabEvent(tab: Tab) {
    this.selected.next(tab);
  }
}
