<pds-module-header class="order-list" title="Vendas">
  <pds-new-breadcrumb [items]="breadcrumb"></pds-new-breadcrumb>

  <pds-panel>
    <peca-order-search (search)="onSearch($event)" />

    <hr />

    <div class="order-list__content" *ngIf="orders$ | async; let orders">
      <ng-container *ngIf="orders.loading">
        <strong>carregando...</strong>
      </ng-container>

      <ng-container *ngIf="orders.failure">
        <strong>Falha ao carregar vendas.</strong>
      </ng-container>

      <ng-container *ngIf="orders.data && !orders.data.length">
        <strong>Nenhuma venda encontrada.</strong>
      </ng-container>

      <pds-table *ngIf="orders.data?.length">
        <div class="mb-24 flex">
          <pds-button icon="refresh" (click)="onClickRefresh()">Reprocessar</pds-button>
        </div>
        <table class="order-list__content__table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Comprador</th>
              <th>Canal de Venda</th>
              <th>Status</th>
              <th>Valor</th>
              <th>Data / Hora</th>
              <th>NFe</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <ng-container *ngFor="let order of orders.data">
              <tr [class.--is-active]="order.expanded">
                <td>{{ order.commerceReference }}</td>
                <td>{{ order.buyer.tradingName }}</td>
                <td>{{ order.salesChannel.name }}</td>
                <td>
                  <pds-chip [content]="order.internalStatus | orderStatus" [color]="order.internalStatus | orderStatus : 'color'" />
                </td>
                <td>
                  <span>{{ order.finalPrice | currency : 'BRL' }}</span>
                </td>
                <td>{{ order.audit?.createdAt | date : 'dd/MM/yy HH:mm' }}</td>
                <td>
                  @if (!order.invoice) {
                  <span>-</span>
                  } @else if (order.invoice.nfNumber) {
                  <span pdsChip (click)="onClickPreviewInvoice(order)" style="cursor: pointer">
                    <span>Nº {{ order.invoice.nfNumber }}</span>
                    <span class="icon">download</span>
                  </span>
                  } @else {
                  <pds-chip [color]="order.invoice.status | orderInvoiceStatus : true" [content]="order.invoice.status | orderInvoiceStatus" />
                  }
                </td>
                <td pdsColActions>
                  <pds-icon-button title="Detalhes" icon="{{ order.expanded ? 'expand_less' : 'expand_more' }}" (click)="onClickExpand(order)" />
                </td>
              </tr>

              <tr [class.--is-expanded]="order.expanded" [hidden]="!order.expanded">
                <td colspan="8">
                  <div class="order-list__details">
                    <peca-order-summary [order]="order" />

                    <peca-order-list-details [orderId]="order.id" [expanded]="order.expanded" />

                    <hr />

                    <pds-form-actions>
                      <pds-button (click)="onClickDetails(order)">ver detalhes</pds-button>
                    </pds-form-actions>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </pds-table>

      <pds-pagination [pagination]="pagination$" (paginate)="onPaginate($event)" />
      <peca-document-preview #documentPreview [disableDeleteAction]="true" />
    </div>
  </pds-panel>
</pds-module-header>
