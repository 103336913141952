<pds-module-header title="B2B" ctaLabel="Novo B2B" ctaPath="/b2b/novo">
  <pds-new-breadcrumb [items]="breadcrumbItems"></pds-new-breadcrumb>

  <div *ngIf="b2bs$ | async; let b2bs">
    <pds-panel>
      <div>
        <pds-form [formGroup]="searchForm">
          <pds-form-row grid="2-1">
            <pds-form-field label="Busca">
              <input type="text" formControlName="search" />
            </pds-form-field>

            <pds-form-field label="Status">
              <select formControlName="active">
                <option value="">Todos</option>
                <option value="true">Ativos</option>
                <option value="false">Inativos</option>
              </select>
            </pds-form-field>
          </pds-form-row>

          <pds-form-actions>
            <pds-button type="submit" (click)="onSubmitSearch()">Buscar</pds-button>
          </pds-form-actions>
        </pds-form>
      </div>
    </pds-panel>

    <hr />

    <div>
      <ng-container *ngIf="b2bs.data?.items?.length">
        <pds-table>
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>CNPJ</th>
                <th>Descontos</th>
                <th>Mtd. de pagamento</th>
                <th>Carrinho</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of b2bs.data?.items">
                <tr>
                  <td>{{ item.buyer.companyName }}</td>
                  <td>{{ item.buyer.cnpj }}</td>
                  <td>
                    <ng-container *ngFor="let item of item.vouchers">
                      <span class="chip">{{ item.name }}</span>
                    </ng-container>
                  </td>
                  <td>{{ item.paymentMethod.name }}</td>
                  <td>
                    <ng-container *ngFor="let item of item.cartStrategy">
                      <span class="chip">{{ item }}</span>
                    </ng-container>
                  </td>
                  <td pdsColActions>
                    <pds-switch [(ngModel)]="item.active" (ngModelChange)="onChangeB2BStatus(item)"></pds-switch>
                  </td>
                  <td pdsColActions>
                    <pds-icon-button (click)="onClickDetails(item)" icon="visibility" />
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </pds-table>
        <pds-pagination [pagination]="pagination$" (paginate)="onPaginate($event)"></pds-pagination>
      </ng-container>
      <ng-container *ngIf="b2bs.data && !b2bs.data?.items?.length">
        <strong>Nenhum parceiro B2B encontrado.</strong>
      </ng-container>
      <ng-container *ngIf="b2bs.loading">
        <strong>carregando...</strong>
      </ng-container>
      <ng-container *ngIf="b2bs.failure">
        <strong>Falha ao carregar parceiros B2B.</strong>
      </ng-container>
    </div>
  </div>
</pds-module-header>

<peca-confirmable-action [confirmable]="changeB2BStatusConfirmable$" (confirm)="onConfirmB2BStatusChanges($event)">
  <p class="mb-8">Você está alterando o status de um parceiro B2B.</p>
  <p>Deseja continuar?</p>
</peca-confirmable-action>
