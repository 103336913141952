<pds-module-header title="Canais de venda" ctaLabel="Novo Canal" ctaPath="/canais-de-venda/novo">
  <pds-new-breadcrumb [items]="breadcrumbItems"></pds-new-breadcrumb>

  <div *ngIf="salesChannels$ | async; let salesChannels">
    <pds-panel>
      <div>
        <pds-form [formGroup]="searchForm">
          <pds-form-row grid="2-1">
            <pds-form-field label="Busca">
              <input type="text" formControlName="search" placeholder="Pesquise por nome do canal" />
            </pds-form-field>

            <pds-form-field label="Status">
              <select formControlName="active">
                <option value="">Todos</option>
                <option value="true">Ativos</option>
                <option value="false">Inativos</option>
              </select>
            </pds-form-field>
          </pds-form-row>

          <pds-form-actions>
            <pds-button type="submit" (click)="onSubmitSearch()">Buscar</pds-button>
          </pds-form-actions>
        </pds-form>
      </div>
    </pds-panel>

    <ng-container *ngIf="salesChannels.data?.items?.length">
      <pds-table>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Taxa</th>
              <th>Tipo de Frete</th>
              <th>Métodos de pagamento</th>
              <th>Carrinho</th>
              <th>Tempo mínimo de entrega</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of salesChannels.data?.items">
              <tr>
                <td>{{ item.name }}</td>
                <td>{{ item.hasConvenienceFee ? 'SIM' : 'NÃO' }}</td>
                <td>{{ item.freightType | freightType }}</td>
                <td>
                  <div class="inline-flex gap-2 flex-wrap-wrap justify-content-center">
                    @for (item of item.paymentMethods; track $index) {
                    <span pdsChip>{{ item.name }}</span>
                    } @empty {
                    <span>-</span>
                    }
                  </div>
                </td>
                <td>{{ item.applyCartStrategy ? 'SIM' : 'NÃO' }}</td>
                <td>{{ item.deliveryTime }}h</td>
                <td pdsColActions>
                  <pds-switch [(ngModel)]="item.active" (ngModelChange)="onChangeSalesChannelStatus(item)"></pds-switch>
                </td>
                <td pdsColActions>
                  <pds-icon-button icon="visibility" title="Detalhes" modifier="outlined" (click)="onClickDetails(item)" />
                  <pds-icon-button icon="lock_reset" title="Limpar limite de busca" modifier="outlined" (click)="onClickResetRateLimit(item)" />
                  <pds-icon-button icon="delete" title="Remover" modifier="outlined" (click)="onClickRemove(item)" />
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </pds-table>

      <pds-pagination [pagination]="pagination$" (paginate)="handlePaginateEvent($event)"></pds-pagination>
    </ng-container>

    <ng-container *ngIf="salesChannels.data && !salesChannels.data?.items?.length">
      <strong>Nenhum canal de venda encontrado.</strong>
    </ng-container>

    <ng-container *ngIf="salesChannels.loading">
      <strong>carregando...</strong>
    </ng-container>

    <ng-container *ngIf="salesChannels.failure">
      <strong>Falha ao carregar canais de venda.</strong>
    </ng-container>
  </div>
</pds-module-header>
