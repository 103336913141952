import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesignSystemModule } from '@peca/design-system';

import { ConfirmableActionComponent } from './components/confirmable-action/confirmable-action.component';
import { ToastComponent } from './components/toast/toast.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { UserExpressComponent } from './components/user-express/user-express.component';
import { ReactiveFormsModule } from '@angular/forms';

const exportables = [
  ConfirmableActionComponent,
  ToastComponent,
  LoadingOverlayComponent,
  UserExpressComponent,
];

@NgModule({
  imports: [CommonModule, DesignSystemModule, ReactiveFormsModule],
  declarations: [...exportables],
  exports: [...exportables],
})
export class CommonsModule {}
