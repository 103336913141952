import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'pds-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class PDSUploadComponent {
  @Output()
  upload: EventEmitter<File>;
  @ViewChild('modal')
  modal!: ModalComponent;
  @ViewChild('fileInput')
  fileInput!: ElementRef<HTMLInputElement>;
  previewImage: string | ArrayBuffer | null;
  fileName: string | null;
  file: File | null;

  constructor() {
    this.upload = new EventEmitter<File>();
    this.previewImage = null;
    this.fileName = null;
    this.file = null;
  }

  open() {
    this.modal.open();
  }

  close() {
    this.file = null;
    this.previewImage = null;
    this.fileName = null;
    this.modal.close();
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer?.files.length) {
      this.handleFile(event.dataTransfer.files[0]);
    }
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      this.handleFile(input.files[0]);
    }
  }

  onClickAttch() {
    if (!this.file) return;
    this.upload.next(this.file);
  }

  private handleFile(file: File): void {
    this.fileName = file.name;
    this.file = file;
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.previewImage = e.target?.result || null;
      };
      reader.readAsDataURL(file);
    } else {
      this.previewImage = null;
    }
  }
}
